import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        borderTop: "1px #494949 solid",
        minHeight: "fit-content",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "space-between",
        px: 1,
        py: 0.5,
      }}
    >
      {!isMobile && (
        <Typography variant="body2">{t("footer.author")}</Typography>
      )}
      <Link
        href="https://beian.miit.gov.cn"
        variant="body2"
        target="_blank"
        rel="noreferrer"
      >
        {t("footer.icp")}
      </Link>

      <Typography variant="body2">{t("footer.ffRights")}</Typography>
    </Box>
  );
};

export default Footer;
