import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

// 为 slice state 定义一个类型
interface LoadingState {
  loading: boolean;
  loadingText: string;
}

interface RightPanelState {
  openRightPanel: boolean;
  rightPanelInfo: any;
}

interface GlobalState {
  loading: boolean;
  loadingText: string;
  openRightPanel: boolean;
  rightPanelInfo: any;
  now: number;
}

// 使用该类型定义初始 state
const initialState: GlobalState = {
  loading: false,
  loadingText: '',
  openRightPanel: false,
  rightPanelInfo: {},
  now: Date.now(),
};

export const globalSlice = createSlice({
  name: 'global',
  // `createSlice` 将从 `initialState` 参数推断 state 类型
  initialState,
  reducers: {
    // 使用 PayloadAction 类型声明 `action.payload` 的内容
    setLoading: (state, action: PayloadAction<LoadingState>) => {
      // console.log('set loading', action.payload);
      state.loading = action.payload.loading;
      state.loadingText = action.payload.loadingText;
    },
    setRightPanel: (state, action: PayloadAction<RightPanelState>) => {
      state.openRightPanel = action.payload.openRightPanel;
      state.rightPanelInfo = action.payload.rightPanelInfo;
    },
    setNow: (state, action: PayloadAction<number>) => {
      state.now = action.payload;
    },
  },
});

export const { setLoading, setRightPanel, setNow } = globalSlice.actions;
// 选择器等其他代码可以使用导入的 `RootState` 类型
export const selectLoading = (state: RootState) =>
  state.global.loading ?? initialState.loading;
export const selectOpenRightPanel = (state: RootState) =>
  state.global.openRightPanel ?? initialState.openRightPanel;
export const selectRightPanelInfo = (state: RootState) =>
  state.global.rightPanelInfo ?? initialState.rightPanelInfo;
export const selectNow = (state: RootState) =>
  state.global.now ?? initialState.now;

export default globalSlice.reducer;
