import * as weatherDict from "./raw/weather.json";
import * as nameDict from "./raw/weatherName.json";
import { EIcon, EName } from "../models/base";
import { EWeather } from "../models/EWeather";

type WeatherNameDict = {
  [key: number]: {
    chs?: string;
    en?: string;
    ja?: string;
  };
};

type WeatherDict = {
  [key: number]: Weather;
};

type Weather = {
  id: number;
  icon: number;
};

class WeatherRepository {
  readonly data: Map<number, EWeather>;

  constructor(dict: WeatherDict, nameDict: WeatherNameDict) {
    this.data = new Map();
    for (let key in dict) {
      if (+key > 0) {
        const itemInfo = dict[key];
        const { chs, en, ja } = nameDict[itemInfo.id];
        this.data.set(itemInfo.id, {
          id: itemInfo.id,
          name: new EName({ chs, en, ja }),
          icon: new EIcon(itemInfo.icon),
        } as EWeather);
      }
    }
  }

  get(id: number) {
    return this.data.get(id)!!;
  }
}

const WeatherRepo = new WeatherRepository(weatherDict, nameDict);

export default WeatherRepo;
