import React from "react";
import {
  Chip,
  FormControl,
  Icon,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import {
  selectFilterRouteTarget,
  setFilterRouteTargets,
} from "../../store/oceanFishingSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectGameDataLocale } from "../../store/settingsSlice";
import IKDRepo, {
  OceanFishingTimeIds,
  OceanFishingTimes,
} from "../../data/ikd";
import Box from "@mui/material/Box";
import { Locale } from "../../models/base";
import { RIcon } from "../../atomic/RIcon";
import FishRepo from "../../data/fishParameter";
import AchievementRepo from "../../data/achievement";
import IKDRecommendationIcon from "./IKDRecommedationIcon";

interface OptionGroup {
  id: string;
  type: string;
  name: string;
  options: {
    name: string;
    icon: any;
    value: string;
  }[];
}

export interface TargetValue {
  type: string;
  title: string;
}

export interface SpotTargetValue extends TargetValue {
  spot: number;
  time: number;
}

export interface BlueFishTargetValue extends TargetValue {
  itemId: number;
}

export interface AchievementTargetValue extends TargetValue {
  achievementId: number;
}

export const decodeTargetValue = (
  value: string,
  locale: Locale,
  t: (key: string) => string
):
  | SpotTargetValue
  | BlueFishTargetValue
  | AchievementTargetValue
  | TargetValue => {
  const [type, ...rest] = value.split("-");
  switch (type) {
    case "spot": {
      const [spotId, timeId] = rest.map((it) => parseInt(it));
      const spotName =
        IKDRepo.getSpot(spotId).mainSpot.name.getLocaleName(locale);
      const timeName = IKDRepo.getTime(timeId).name.getLocaleName(locale);
      const spotTimeName = `${spotName}(${timeName})`;
      return {
        type,
        title: spotTimeName,
        spot: spotId,
        time: timeId,
      };
    }
    case "bluefish": {
      const [itemId] = rest;
      const _itemId = +itemId;
      return {
        type,
        title: FishRepo.get(_itemId).name.getLocaleName(locale),
        itemId: _itemId,
      };
    }
    case "achievement": {
      const [achievementId] = rest;
      const _achievementId = +achievementId;
      return {
        type,
        title: AchievementRepo.get(_achievementId).name.getLocaleName(locale),
        achievementId: _achievementId,
      };
    }
    case "recommended":
      return { type, title: t("oceanFishing.filters.type.recommended") };
    default: {
      return { type, title: type };
    }
  }
};

const _getChipLabel = (
  value: string,
  locale: Locale,
  t: (key: string) => string
) => {
  const { title } = decodeTargetValue(value, locale, t);
  return title;
};

const _encodeSpotValue = (type: string, spotId: number, timeId: number) =>
  `${type}-${spotId}-${timeId}`;

const _encodeBlueFishValue = (type: string, timeId: number) =>
  `${type}-${timeId}`;

const _encodeAchievementValue = (type: string, achievementId: number) =>
  `${type}-${achievementId}`;

const useFilters = () => {
  const locale = useAppSelector(selectGameDataLocale);
  const filters: OptionGroup[] = [];
  const { t } = useTranslation();

  // achievement filters
  const achievementOptions = IKDRepo.allRouteAchievements.map(({ achievement }) => {
    return {
      value: _encodeAchievementValue("achievement", achievement.id),
      icon: (
        <RIcon
          src={achievement.icon.toIconUrl({ source: "momola" })}
          label={achievement.name}
        ></RIcon>
      ),
      name: achievement.name.getLocaleName(locale),
    };
  });
  const recommendationOption = {
    value: "recommended",
    icon: <IKDRecommendationIcon />,
    name: t("oceanFishing.filters.type.recommended"),
  };
  filters.push({
    id: "achievement",
    type: "achievement",
    name: t("oceanFishing.filters.type.achievement"),
    options: [recommendationOption, ...achievementOptions],
  });

  // blue fish filters
  filters.push({
    id: "bluefish",
    type: "bluefish",
    name: t("oceanFishing.filters.type.blueFish"),
    options: IKDRepo.allBlueFishes.map((fish) => {
      return {
        value: _encodeBlueFishValue("bluefish", fish.id),
        icon: (
          <RIcon
            src={fish.icon.toIconUrl({ source: "momola" })}
            label={fish.name}
          ></RIcon>
        ),
        name: fish.name.getLocaleName(locale),
      };
    }),
  });

  // route filters
  filters.push(
    ...IKDRepo.allSpots.map((spot) => {
      const spotGeneralName = spot.name.getLocaleName(locale);
      const spotName = spot.mainSpot.name.getLocaleName(locale);
      return {
        id: "spot-" + spot.id,
        type: "spot",
        name: spotGeneralName,
        options: OceanFishingTimeIds.map((time) => {
          const spotTimeName = `${spotName}(${OceanFishingTimes[
            time
          ].name.getLocaleName(locale)})`;
          const icon = OceanFishingTimes[time].icon;
          return {
            value: _encodeSpotValue("spot", spot.id, time),
            icon: icon && <Icon sx={{ mr: 1 }} component={icon}></Icon>,
            name: spotTimeName,
          };
        }),
      };
    })
  );

  return filters;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 9.5 + ITEM_PADDING_TOP,
    },
  },
};

export const IKDScheduleTargetInput: React.FC = () => {
  const { t } = useTranslation();
  const locale = useAppSelector(selectGameDataLocale);
  const targets = useAppSelector(selectFilterRouteTarget);
  const dispatch = useAppDispatch();

  const handleChange = (event: SelectChangeEvent<typeof targets>) => {
    const {
      target: { value },
    } = event;
    dispatch(
      setFilterRouteTargets(
        typeof value === "string" ? value.split(",") : value
      )
    );
  };
  const filters = useFilters();
  return (
    <FormControl fullWidth>
      <InputLabel id="route-type-multi-select-label">
        {t("oceanFishing.filters.type.title")}
      </InputLabel>
      <Select
        labelId="route-type-multi-select-label"
        id="route-type-multi-select"
        value={targets}
        multiple
        onChange={handleChange}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={_getChipLabel(value, locale, t)} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {filters.flatMap((g) => {
          return [
            <ListSubheader key={g.id}>{g.name}</ListSubheader>,
            ...g.options.map((it) => {
              return (
                <MenuItem value={it.value} key={it.value}>
                  {it.icon}
                  <Typography>{it.name}</Typography>
                </MenuItem>
              );
            }),
          ];
        })}
      </Select>
    </FormControl>
  );
};
