import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { EMJIAnimalSize } from "../models/EMJI";
import { CountDown } from "../services/mjiAnimalService";

export type CountDownCache = { [key: number]: CountDown };

export type AnimalVersionOption = "previous" | "current";

interface AnimalsState {
  pinned: number[];
  animalSizeFilter: AnimalSizeOption[];
  animalVersionFilter: AnimalVersionOption[];
  countDown: CountDownCache;
}

export type AnimalSizeOption = EMJIAnimalSize;

// 使用该类型定义初始 state
const initialState: AnimalsState = {
  pinned: [],
  animalSizeFilter: ["small", "medium", "large"],
  animalVersionFilter: ["previous", "current"],
  countDown: {},
};

export const animalsSlice = createSlice({
  name: "animals",
  // `createSlice` 将从 `initialState` 参数推断 state 类型
  initialState,
  reducers: {
    // 使用 PayloadAction 类型声明 `action.payload` 的内容
    setPinned: (state, action: PayloadAction<number>) => {
      if (!(state.pinned ?? initialState.pinned).includes(action.payload)) {
        state.pinned.push(action.payload);
      }
    },
    setUnPinned: (state, action: PayloadAction<number>) => {
      state.pinned = state.pinned.filter((it) => it !== action.payload);
    },
    setAnimalSizeFilter: (state, action: PayloadAction<AnimalSizeOption[]>) => {
      state.animalSizeFilter = action.payload;
    },
    setCountDown: (state, action: PayloadAction<CountDownCache>) => {
      state.countDown = action.payload;
    },
    setAnimalVersionFilter: (
      state,
      action: PayloadAction<AnimalVersionOption[]>
    ) => {
      state.animalVersionFilter = action.payload;
    },
  },
});

export const {
  setPinned,
  setUnPinned,
  setAnimalSizeFilter,
  setCountDown,
  setAnimalVersionFilter,
} = animalsSlice.actions;
// 选择器等其他代码可以使用导入的 `RootState` 类型
export const selectPinned = (state: RootState) =>
  state.animals.pinned ?? initialState.pinned;
export const selectAnimalSizeFilter = (state: RootState) =>
  state.animals.animalSizeFilter ?? initialState.animalSizeFilter;
export const selectAnimalCountDown = (state: RootState) =>
  state.animals.countDown ?? initialState.countDown;
export const selectAnimalVersionFilter = (state: RootState) =>
  state.animals.animalVersionFilter ?? initialState.animalVersionFilter;
export default animalsSlice.reducer;
