import { chain, concat, isEmpty, partition } from "lodash";

import { IKDRouteTable } from "@ffxiv-momola/data";
import IKDRepo from "../data/ikd";
import { EOceanFishingRoute } from "../models/EOceanFishing";

const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;

const routeTable = IKDRouteTable.routeTable;

const routeN = routeTable[0].length;
const routeTypes0 = chain(routeTable[0]).uniq().sort().value();
const routeTypes1 = chain(routeTable[1]).uniq().sort().value();
export const routeTypesByGroup = [routeTypes0, routeTypes1];
export const routeTypes = concat([], routeTypes0, routeTypes1);

type RouteTime = {
  time: number;
  routeType: number;
};

export type RouteInfo = {
  lt: number;
  missions: any[];
} & EOceanFishingRoute;

const assembleRoutes = (routeTimes: RouteTime): RouteInfo => {
  const route = IKDRepo.getRoute(routeTimes.routeType)!!;

  // assemble achievements
  // assemble missions
  return {
    ...route,
    lt: routeTimes.time,
    missions: [],
  };
};

export const getRoutes = (
  time: number = Date.now(),
  count: number = 12,
  targets = routeTypes
) => {
  const [targetRouteTypes0, targetRouteTypes1] = partition(targets, (it) =>
    routeTypes0.includes(it)
  );
  const routeInfo = chain(
    _getRoutes(time, count, targetRouteTypes0, routeTable[0])
  )
    .concat(_getRoutes(time, count, targetRouteTypes1, routeTable[1]))
    .sortBy(["time", "type"])
    .take(count)
    .value();

  return chain(routeInfo).map(assembleRoutes).value();
};

const _getRoutes = (
  time: number,
  count: number,
  targetRouteTypes: number[],
  targetRouteTable: number[]
) => {
  if (isEmpty(targetRouteTypes)) {
    return [];
  }
  const MAGIC_OFFSET = -8 + routeN;
  const routeOffset = Math.floor(Math.floor(time / HOUR) / 2);
  const startCheckPoint = time - (time % (2 * HOUR));
  const targetRoutes: RouteTime[] = [];
  for (let i = 0; targetRoutes.length < count; i++) {
    const type = targetRouteTable[(routeOffset + MAGIC_OFFSET + i) % routeN];
    if (targetRouteTypes.includes(type)) {
      targetRoutes.push({
        time: startCheckPoint + i * 2 * HOUR,
        routeType: type,
      });
    }
  }
  return targetRoutes;
};
export const shiftTimeForLimit = (time: number, limit: number) => {
  const startCheckPoint = time - (time % (2 * HOUR));
  if (time % (2 * HOUR) < limit) {
    return startCheckPoint;
  } else {
    return startCheckPoint + 2 * HOUR;
  }
};
export const shiftTimeForCheckInLimit = (time: number) =>
  shiftTimeForLimit(time, 60 * MINUTE);
