import React, { Fragment } from "react";
import { format } from "date-fns";
import { chain, isEmpty, some } from "lodash";
import { useTranslation } from "react-i18next";

import {
  Divider,
  List,
  ListSubheader,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import {
  getRoutes,
  RouteInfo,
  routeTypes,
  shiftTimeForCheckInLimit,
} from "../../core/oceanFishing";
import IKDRepo from "../../data/ikd";
import { useAppSelector } from "../../store/hooks";
import {
  selectFilterRouteTarget,
  selectRouteCount,
  selectRouteGroup,
} from "../../store/oceanFishingSlice";

import { selectGameDataLocale } from "../../store/settingsSlice";
import {
  AchievementTargetValue,
  BlueFishTargetValue,
  decodeTargetValue,
  SpotTargetValue,
} from "./IKDScheduleTargetInput";
import { IKDScheduleRow, RouteInfoUI } from "./IKDScheduleRow";
import { useNavigate } from "react-router-dom";

const useRouteTypes = () => {
  // TODO get filter types here and convert to route types
  const locale = useAppSelector(selectGameDataLocale);
  const routeGroup = useAppSelector(selectRouteGroup);
  const filterTargets = useAppSelector(selectFilterRouteTarget);
  const { t } = useTranslation();
  // get spot filters
  const spotFilters = filterTargets
    .map((it) => decodeTargetValue(it, locale, t))
    .filter((it) => it.type === "spot") as SpotTargetValue[];
  // get bluefish filters
  const bluefishFilters = filterTargets
    .map((it) => decodeTargetValue(it, locale, t))
    .filter((it) => it.type === "bluefish") as BlueFishTargetValue[];
  // get achievement filters
  const achievementFilters = filterTargets
    .map((it) => decodeTargetValue(it, locale, t))
    .filter((it) => it.type === "achievement") as AchievementTargetValue[];
  // get show recommended filter
  const hasRecommendFilter = filterTargets.includes("recommended");

  const allRoutes = IKDRepo.allRoutes;
  const targetFilerTypes = isEmpty(filterTargets)
    ? routeTypes // no filter, show all
    : allRoutes
        .filter((route) => {
          const isSpotMatched = route.spots.some((spot, idx) => {
            return spotFilters.some((filter) => {
              return (
                spot.id === filter.spot && route.times[idx].id === filter.time
              );
            });
          });

          const isBlueFishMatched = some(route.availableBlueFishes, (bf) =>
            some(bluefishFilters, { itemId: bf.itemId })
          );

          const isAchievementMatched = some(route.achievements, (a) =>
            some(achievementFilters, { achievementId: a.achievement.id })
          );

          const isRecommendedMatched =
            hasRecommendFilter && route.isRecommended;
          console.debug({
            id: route.id,
            isSpotMatched,
            isBlueFishMatched,
            isAchievementMatched,
            isRecommendedMatched,
            filterTargets,
          });
          return (
            isSpotMatched ||
            isBlueFishMatched ||
            isAchievementMatched ||
            isRecommendedMatched
          );
        })
        .map((it) => it.type);

  const routeTypesOfGroup =
    routeGroup === "all"
      ? routeTypes
      : IKDRepo.getRouteGroup(routeGroup)?.routeTypes ?? routeTypes;

  return chain(routeTypesOfGroup).intersection(targetFilerTypes).uniq().value();
};

const _formatDate = (datetime: number) => format(new Date(datetime), "MM-dd");
const _formatTime = (datetime: number) => format(new Date(datetime), "HH:mm");
const _formatDateTime = (datetime: number) =>
  format(new Date(datetime), "MM-dd HH:mm");

const _attachFormattedTime = (routes: RouteInfo[]): RouteInfoUI[] => {
  return routes.map((route, idx) => {
    let timeStr;
    if (idx === 0) {
      timeStr = _formatDateTime(route.lt);
    } else {
      const prevRoute = routes[idx - 1];
      if (_formatDate(route.lt) === _formatDate(prevRoute.lt)) {
        timeStr = _formatTime(route.lt);
      } else {
        timeStr = _formatDateTime(route.lt);
      }
    }
    return {
      ...route,
      formattedTime: timeStr,
    };
  });
};

export const IKDScheduleList: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const count = useAppSelector(selectRouteCount);
  const navigate = useNavigate();

  const filterTypes = useRouteTypes();
  console.debug(filterTypes);
  const routes = React.useMemo(
    () =>
      _attachFormattedTime(
        getRoutes(shiftTimeForCheckInLimit(Date.now()), count, filterTypes)
      ),
    [count, filterTypes]
  );

  const handleRouteClick = (route: RouteInfo) => {
    console.debug(route);
    // jump to route page
    navigate(`/oceanFishing/route/${route.id}`);
  };

  return (
    <List
      subheader={
        <ListSubheader component="div" id="ocean-fishing-header" sx={{ my: 1 }}>
          <Grid container spacing={2}>
            <Grid xs={6} sm={2}>
              <Typography align="right">
                {t("oceanFishing.list.header.lt")}
              </Typography>
            </Grid>
            <Grid xs={6} sm={3}>
              <Typography>{t("oceanFishing.list.header.target")}</Typography>
            </Grid>
            {!isMobile && (
              <>
                <Grid xs={6} sm={2}>
                  <Typography>{t("oceanFishing.list.header.route")}</Typography>
                </Grid>
                <Grid xs={6} sm={5}>
                  <Typography>{t("oceanFishing.list.header.spots")}</Typography>
                </Grid>
              </>
            )}
          </Grid>
        </ListSubheader>
      }
    >
      {routes.map((route, idx) => (
        <Fragment key={route.id + "-" + idx}>
          <Divider />
          <IKDScheduleRow
            route={route}
            onClick={() => handleRouteClick(route)}
          />
        </Fragment>
      ))}
    </List>
  );
};
