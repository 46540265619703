import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import {
  Box,
  Card,
  CardHeader,
  ListSubheader,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

import { EOceanFishingRoute } from "../../models/EOceanFishing";
import { RToggleButtons } from "../../atomic/RToggleButtons";
import { selectGameDataLocale } from "../../store/settingsSlice";
import { useAppSelector } from "../../store/hooks";
import { RIcon } from "../../atomic/RIcon";

import { SpotCard } from "./SpotCard";
import { convertTip } from "./spotHelper";
import { IKDTipNotification } from "./IKDTipNotification";

interface FilterProps {
  achievementId: number;
  onChange: (it: number) => void;
  achievementOptions: { id: number; name: ReactNode }[];
}
const Filter: React.FC<FilterProps> = ({
  achievementId,
  onChange,
  achievementOptions,
}) => {
  const { t } = useTranslation();

  return (
    <Paper sx={{ mt: 1 }}>
      <ListSubheader>{t("oceanFishing.filters.title")}</ListSubheader>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          display: "grid",
          gridTemplateColumns: { sm: "1fr 1fr" },
          gap: 2,
          p: 2,
        }}
      >
        <RToggleButtons
          label={t("oceanFishing.route.type.achievement")}
          value={achievementId}
          options={achievementOptions}
          exclusive
          mandatory
          onChange={onChange}
        />
      </Box>
    </Paper>
  );
};

interface IKDRouteAchievementProps {
  route: EOceanFishingRoute;
}

export const IKDRouteAchievement: React.FC<IKDRouteAchievementProps> = ({
  route,
}) => {
  const locale = useAppSelector(selectGameDataLocale);

  const [achievementId, setAchievementId] = React.useState(
    route.achievements[0]?.achievement?.id
  );

  const achievementOptions = route.achievements.map((a) => ({
    id: a.achievement.id,
    name: (
      <>
        <RIcon icon={a.achievement.icon} size="small" />
        <Typography>{a.achievement.name.getLocaleName(locale)}</Typography>
      </>
    ),
  }));

  const achievement = route.achievements.find(
    (a) => a.achievement.id === achievementId
  );

  return (
    <>
      <IKDTipNotification />

      {route.achievements.length > 0 ? (
        <>
          <Filter
            achievementId={achievementId}
            onChange={(achievementId) => setAchievementId(achievementId)}
            achievementOptions={achievementOptions}
          />
          <Grid2 container spacing={2} sx={{ mt: 1 }}>
            {route.spots.map((spot, spotIdx) => {
              const isFreeSpot = isEmpty(
                achievement?.tips?.[spot.subSpot.id]?.fishes
              );
              const time = route.times[spotIdx];
              const subFishes =
                achievement?.tips?.[spot.subSpot.id]?.fishes?.filter((f) =>
                  f.ikdTimes.map((it) => it.id).includes(time.id)
                ) ?? [];
              return (
                <Grid2 xs={12} sm={4} key={spot.id}>
                  {isFreeSpot ? (
                    <Card>
                      <CardHeader
                        title={
                          <Typography variant="body1" component="div">
                            {convertTip(
                              achievement?.tips?.[spot.mainSpot.id]?.content
                            )}
                          </Typography>
                        }
                      />
                    </Card>
                  ) : (
                    <Stack spacing={2}>
                      <SpotCard
                        spot={spot.mainSpot}
                        variant="fish"
                        fishes={
                          achievement?.tips?.[spot.mainSpot.id]?.fishes ?? []
                        }
                        tip={convertTip(
                          achievement?.tips?.[spot.mainSpot.id]?.content
                        )}
                      />
                      <SpotCard
                        spot={spot.subSpot}
                        variant="fish"
                        fishes={subFishes}
                        tip={convertTip(
                          achievement?.tips?.[spot.subSpot.id]?.content
                        )}
                        time={time}
                      />
                    </Stack>
                  )}
                </Grid2>
              );
            })}
          </Grid2>
        </>
      ) : (
        <Typography variant="body1">No achievement</Typography>
      )}
    </>
  );
};
