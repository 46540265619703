import { FishParameter as FishParameterData } from "@ffxiv-momola/data";

import { EFish } from "../models/EFish";

import ItemRepo from "./item";

type FishParameter = {
  id: number;
  itemId: number;
  isHidden: boolean;
  isInLog: boolean;
  oceanStars: number;
};

class FishRepository {
  readonly data: Map<number, EFish>;

  constructor(fishParameters: FishParameter[]) {
    this.data = new Map();
    for (let fishParameter of fishParameters) {
      // skip if item id not exists e.g. 2001422
      if (!ItemRepo.get(fishParameter.itemId)) continue;

      // fish spot is updated in FishingSpotRepo
      this.data.set(
        fishParameter.itemId,
        EFish.fromItem(ItemRepo.get(fishParameter.itemId), fishParameter)
      );
    }
  }

  get(id: number) {
    return this.data.get(id)!!;
  }
}

const FishRepo = new FishRepository(FishParameterData);

export default FishRepo;
