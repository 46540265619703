import { CountDownType } from "../models/base";
import { FISH_WINDOW_FORECAST_N } from "../data/env/constants";
import { nextNWindows } from "../core/forecast";
import { EMJIAnimal } from "../models/EMJI";
import mjiService from "./mjiService";

const MjiAnimalService = {};

export interface CountDown {
  type: CountDownType;
  timePoint?: number;
  nextTimePoint?: number;
  itemWindowTotal?: number;
}

type WindowCache = { [key: number]: number[][] };
const cache: WindowCache = {};

function updateCache(animal: EMJIAnimal, territoryTypeId: number, now: number) {
  const remainingFishWindows = (cache[animal.id] ?? []).filter(
    (it) => it[1] > now
  );

  const missingFishWindowN =
    FISH_WINDOW_FORECAST_N - remainingFishWindows.length;

  let fishWindowsComputed;
  if (missingFishWindowN > 0) {
    fishWindowsComputed = getAnimalWindows(animal, territoryTypeId);
    cache[animal.id] = fishWindowsComputed;
  } else {
    fishWindowsComputed = cache[animal.id];
  }

  return fishWindowsComputed;
}

export function getAnimalWindows(
  animal: EMJIAnimal,
  territoryTypeId: number,
  n?: number
) {
  return nextNWindows(
    animal.startHour,
    animal.endHour,
    [],
    animal.weather.map((it) => it.id),
    mjiService.getWeatherRates(territoryTypeId),
    n
  );
}

export function getCountDown(
  animal: EMJIAnimal,
  territoryTypeId: number
): CountDown {
  const now = new Date().getTime();
  let itemWindowsComputed = updateCache(animal, territoryTypeId, now);
  let targetItemWindow;
  let nextTargetItemWindow;

  for (let i = 0; i < itemWindowsComputed.length; i++) {
    if (itemWindowsComputed[i][1] >= now) {
      targetItemWindow = itemWindowsComputed[i];
      if (i + 1 < itemWindowsComputed.length) {
        nextTargetItemWindow = itemWindowsComputed[i + 1];
      }
      break;
    }
  }

  if (targetItemWindow == null) {
    return { type: "AllAvailable" };
  }
  if (now <= targetItemWindow[0]) {
    return {
      type: "Waiting",
      // time: targetItemWindow[0] - now,
      timePoint: targetItemWindow[0],
      itemWindowTotal: targetItemWindow[1] - targetItemWindow[0],
    };
  } else if (now <= targetItemWindow[1]) {
    return {
      type: "Available",
      // time: targetItemWindow[1] - now,
      timePoint: targetItemWindow[1],
      itemWindowTotal: targetItemWindow[1] - targetItemWindow[0],
      // nextInterval: nextTargetItemWindow              ? nextTargetItemWindow[0] - now              : undefined,
      nextTimePoint: nextTargetItemWindow ? nextTargetItemWindow[0] : undefined,
    };
  }

  return { type: "AllAvailable" };
}

export default MjiAnimalService;
