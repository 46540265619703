import React from 'react';
// import { useAppSelector } from '../store/hooks';
// import { selectPinned } from '../store/animalsSlice';
// import { EMJIAnimal } from '../models/mji';



const Task: React.FC = () => {
  // get pinned task from redux
  // const pinned = useAppSelector(selectPinned);

  // const pinnedList = getPinnedList(animalList, pinned);

  // const pinnedTask = [];

  return <>Show Pinned Task Here</>;
};

export default Task;
