const getConfig = (key: string) => {
  const appEnv = process.env.REACT_APP_ENV || "production";
  const variableSuffix = appEnv === "development" ? "_DEV" : "";
  const reactAppSuffix = "REACT_APP_";
  return process.env[`${reactAppSuffix}${key}${variableSuffix}`];
};

const config = {
  SUB_DIRECTORY: getConfig("SUB_DIRECTORY"),
};

export default config;
