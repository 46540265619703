export const IkdTip = {
  references: [
    {
      title: "[5_4] [海钓]海钓个人经验分享",
      link: "https://nga.178.com/read.php?tid=25905000",
      author: "轩辕十四@沃仙曦染",
    },
    {
      title: "[出海垂钓] [6_4] 海钓个人经验分享",
      link: "https://nga.178.com/read.php?tid=37591848",
      author: "轩辕十四@沃仙曦染",
    },
  ],
  pointTip: {
    // 加拉迪翁湾
    237: {
      all: `触发幻海：刺螠，！！！，幻光巨齿鲨，强力
磷虾5-10s！加拉迪翁鳀鱼X3(双2)→直感内磷虾，！！！，醉鱼，强力`,
    },
    238: {
      1: {
        fishList: [29753, 29756],
        content: "石沙蚕，5-10s！，钉牡蛎(双2)、10s+！灵敏舞者(双2)",
      },
      2: {
        fishList: [29751, 29782],
        content: "刺螠，5-6s！！，流银刃(双2)；刺螠，！！！，漏斗鲨(双4)",
      },
      3: {
        fishList: [29788],
        content:
          "(石沙蚕，3s-！，天堂之匙X2)+(磷虾，3-5s！，海神印)→火萤，！！！，索蒂斯",
      },
    },
    // 梅尔托尔海峡南
    239: {
      all: `触发幻海：磷虾，20+！！！，幻光盘丽鱼，精准
(微风强风不出现)磷虾，！！尸鬼梭鱼--！！步兵剑→直感内刺螠，！！！，小利维亚桑，强力`,
    },
    240: {
      1: {
        fishList: [29755],
        content: "磷虾，5s+！！，秘银君(双2)",
      },
      2: {
        fishList: [29757, 29765],
        content:
          "石沙蚕，6s+！，海荨麻(双4)；石沙蚕，3-5s！高级以太药虱--！！！无赖龙(双2)",
      },
      3: {
        fishList: [29763, 29789],
        content: `石沙蚕，3-5s！高级以太药虱--3-6s！！以太海龙(双2)[5-6s有干扰鱼]；
石沙蚕，3-5s！高级以太药虱--3s-！！巨大枪鱼→小虾肉笼，！！！，珊瑚蝠鲼`,
      },
    },
    // 梅尔托尔海峡北
    243: {
      all: `触发幻海：石沙蚕，！！！，幻光海马，精准      
(暴雪不出现)石沙蚕，7-14s！，海投刀—10-16s！！古老恐鱼→直感内石沙蚕，！！！，海流星，精准`,
    },
    244: {
      1: {
        fishList: [29791],
        content: "刺螠，！！！，守领鳍龙X3(双3-4)→重板铁钩，！！！，依拉丝莫龙",
      },
      2: {
        fishList: [29764, 29783],
        content: "石沙蚕，7s+！，珊瑚海龙(双4)；磷虾，8s+！！，坠天鱼(双2)",
      },
      3: {
        fishList: [29777, 29773],
        content:
          "石沙蚕，5-6s！，剪碎手巴塞洛缪(双4)；磷虾，5s+！！，幻纱披风(双4)",
      },
    },
    // 罗塔诺海
    241: {
      all: `触发幻海：刺螠，21s+！！！，幻光鲈，强力        
刺螠，7-14s！！，深红钓鮟鱇X2(双3-4)→直感内磷虾，11-20s！！！，海铠靴，强力`,
    },
    242: {
      1: {
        fishList: [29767, 29770],
        content: "刺螠，5s+！！，清道夫(双2)；刺螠，！！！，处刑者(双4)",
      },
      2: {
        fishList: [29790],
        content:
          "(石沙蚕，3s+！，沉寂者)+(刺螠，5s+！！，深海鳗)→沟鼠尾巴，！！！，石骨鱼",
      },
      3: {
        fishList: [29762, 29774],
        content: "磷虾，7s+！，浮游蝶鱼(双4)；石沙蚕，5-6s！，蜗牛鱼(双2)",
      },
    },
    // 谢尔达莱群岛
    246: {
      all: `触发幻海：石沙蚕，12-19s！！！，幻光蝴蝶鱼，精准        
石沙蚕，5-11s！，金属箱X3(双2)→直感内磷虾，！！！，谢尔达莱结晶，强力`,
    },
    247: {
      1: {
        fishList: [32071, 32072],
        content: "磷虾，7s+！！，恶魔刺(双2)；刺螠，5s+！！！，美鲶(双2)",
      },
      2: {
        fishList: [32069, 32073],
        content: "磷虾，6s+！！，火烈鳗(双2)；刺螠，6s+！！，浮游翻车鱼(双2)",
      },
      3: {
        fishList: [32074],
        content:
          "(刺螠，3s-！！！，喷射蝠鲼X2)+(磷虾，3-5s！！，雾须酒杯)→乌贼丝，！！！，哈弗古法",
      },
    },
    // 绯汐海
    248: {
      all: `触发幻海：磷虾，！！！，幻光鳗，强力        
石沙蚕，！！！，落水面具→直感内磷虾，9-15s！！！，素面，强力`,
    },
    249: {
      1: {
        fishList: [32085, 32090, 32093, 32094],
        content: `石沙蚕，3s-！，神谕蟹(双4)；石沙蚕，6s+！，歼灭者(双4)
常驻高分鱼，刺螠，8s+！！！，石英锤头鲨(双2)
磷虾，3s-！！，幸福愿景X3(双4)→潮虫，！！！，海洋蟾蜍，精准`,
      },
      2: {
        fishList: [32093],
        content: "常驻高分鱼，刺螠，8s+！！！，石英锤头鲨(双2)",
      },
      3: {
        fishList: [32087, 32093],
        content: `磷虾，3s+！！，余韵(双4)[5s+可放心双提]
常驻高分鱼，刺螠，8s+！！！，石英锤头鲨(双2)`,
      },
    },
    // 罗斯利特湾
    250: {
      all: `触发幻海：刺螠，15s+！！！，幻光鱼龙，强力        
石沙蚕，5-10s！，罗斯利特海带X3(双3-4)→石沙蚕，11-18s！！！，银杏鱼，精准`,
    },
    251: {
      1: {
        fishList: [32105, 32109, 32111, 32113],
        content: `石沙蚕，3s-！，鱼酱罐(双4)；磷虾，4-6s！！，珍珠爆弹(双4)[5s+可放心双提]；刺螠，4s+！！，潘诺普忒斯(双4)[5s+可放心双提]
常驻高分鱼，磷虾，6s+！！，刀颚鱼(双2)`,
      },
      2: {
        fishList: [32114, 32113],
        content: `石沙蚕，2-5s！罗斯利特贝--！，巨魔鱼→石沙蚕，2-4s！罗斯利特贝--！！！，盾齿龙
常驻高分鱼，磷虾，6s+！！，刀颚鱼(双2)`,
      },
      3: {
        fishList: [32105, 32109, 32113],
        content: `石沙蚕，3s-！，鱼酱罐(双4)；磷虾，4-6s！！，珍珠爆弹(双4)[5s+可放心双提]
常驻高分鱼，磷虾，6s+！！，刀颚鱼(双2)`,
      },
    },
    // 妖歌海
    286: {
      all: `触发幻海：刺螠，!!!，幻光腔棘鱼，强力
石沙蚕19-25s!诗人的笛子 X2(双2)→直感内刺螠，!!!，幽暗鲨，强力`,
    },
    287: {
      1: {
        fishList: [40532, 40537, 40531, 40535],
        content: `幻海(日)：磷虾，3-4s!!，宽头乌贼(双4)
刺螠，3s-!，潜底腔棘鱼X3(双2)→青花鱼块，6s+!!!，塔尼瓦`,
      },
      2: {
        fishList: [40539, 40532, 40537, 40531, 40535],
        content: `幻海(午)：磷虾，3-4s!!，宽头乌贼(双4)；磷虾7-8s!!，妮美雅的纺车(双2)`,
      },
      3: {
        fishList: [40533, 40537, 40531, 40536, 40535],
        content: `幻海(夜)：磷虾，6-7s!，鲜艳粉红虾(双4)；刺螠5-6s!!!，黑色旋齿鲨(双2)`,
      },
    },
    // 黄金港
    288: {
      all: `触发幻海：石沙蚕，!!!，幻光求仙，精准
石沙蚕，5-9s!，斑纹虾--!!!6-12s鼠鲨→直感内石沙蚕，!!!，水引鱼，精准`,
    },
    289: {
      1: {
        fishList: [40554, 40557, 40553, 40552],
        content: `幻海(日)：磷虾，6-7s!!，毒平鲹(双2);石沙蚕，5-7s!，龙卷螺(双4)`,
      },
      2: {
        fishList: [40554, 40555, 40557, 40559, 40552],
        content: `幻海(午)：石沙蚕，8-10s!，装甲鱼(双2)；石沙蚕，5-7s!，龙卷螺(双4);磷虾，6-7s!!，犁头鳐(双2)`,
      },
      3: {
        fishList: [40554, 40552],
        content: `幻海(夜)：刺螠，!!!，捷疾鬼X2→磷虾，2-3s!!，小判鯙--!!!琉璃龙`,
      },
    },
    // 红玉海
    290: {
      all: `触发幻海：磷虾，16-28s!!!，幻光海蛇，强力
石沙蚕，5-7s!，深红巨藻X3(双2)→直感内磷虾，16-27s!!!，天之虎，强力`,
    },
    291: {
      1: {
        fishList: [40579, 40573, 40577, 40572, 40571],
        content: `幻海(日)：石沙蚕，5-6s!，黑齿海参(双2)；石沙蚕，6-7s!，黄菖蒲(双2)`,
      },
      2: {
        fishList: [40579, 40572, 40571],
        content: `幻海(午)：(磷虾，3-4s!!，芭蕉乌贼X2)+(刺螠，4-7s!!，巨型真鱿)→乌贼丝，!!!，狱之蟹`,
      },
      3: {
        fishList: [40579, 40572, 40571, 40576, 40578],
        content: `幻海(夜)：磷虾，5-6s!!，龟甲团子鱼(双2)；刺螠，6-7s!!，红玉仁王(双2)`,
      },
    },
    // 无二江
    292: {
      all: `触发幻海：刺螠，!!!，幻光骨舌鱼，强力
石沙蚕，7-10s!，平铲鲫鱼X3(双2)→直感内刺螠，!!!，茂鲨，强力`,
    },
    293: {
      1: {
        fishList: [40591, 40594],
        content: `幻海(日)：(石沙蚕，3-4.5s!，玄水虾)+(石沙蚕，3s-!X2，无二虾虎)→石蝇幼虫，5-8s!!!，梅泉宝龟`,
      },
      2: {
        fishList: [40599, 40591, 40594, 40596],
        content: `幻海(午)：磷虾，5-6s!，彩虹鳟(双2)；磷虾，5-6s!!，银鳞五色(双2)`,
      },
      3: {
        fishList: [40597, 40591, 40594, 40598],
        content: `幻海(夜)：磷虾，5-6s!!，返祖鲶鱼精(双2)；刺螠，!!!，无二鳄(双2)`,
      },
    },
  },
  fishTip: {
    // 索蒂斯
    29788: {
      content: `天堂之钥3s内可以自信双提，3.0、3.1为天堂之钥与海神印的重合区间，5s后可直接收杆
                火萤存在较长的中杆可能导致鱼识内仅能抛一杆，触发鱼识后可酌情拍水门票鱼`,
    },
    // 珊瑚蝠鲼
    29789: {
      content: `门票最难的蓝鱼，高级以太药虱为3-5的轻杆，超过5s必为太阳神面纱，巨大枪鱼为3s内中杆，超过3s可直接断杆
                由于门票需要以小钓大，而以小钓大2的cd有3分钟，那么在触发第二次鱼识或者以小钓大2的那一杆没咬的情况下，耐心内双提相比空手套hq药虱期望收益更大一点
                关于航线：罗塔诺海午航线为鲨鱼航线，梅尔托尔海峡夜航线为海马航线，成就车存在时可能会主动地去放弃珊瑚蝠鲼所在海域的幻海来获得更多的机会`,
    },
    // 依拉丝莫龙
    29791: {
      content: `门票为唯一鱼王杆，提就对了！但是注意杆型不要提错了
                在鱼识快结束的那杆(3s左右)如果是守领鳍龙可以选择稍微延后一点提钩直接双提，可以在鱼识结束后再次进鱼识
                重板铁钩存在长杆可能导致鱼识内仅能抛一杆，可以酌情考虑拍水门票鱼使鱼王杆不会歪门票鱼`,
    },
    // 石骨鱼
    29790: {
      content: `沉寂者为大于3s的轻杆，深海鳗为长中杆(甚至能到9s+)，建议全提并酌情拍水
                沟鼠尾巴存在长杆可能导致鱼识内仅能抛一杆，请尽可能的拍掉触发鱼识的那个门票鱼来获取更高的抛两杆的概率`,
    },
    // 哈弗古法
    32074: {
      content: `雾须酒杯为3-5s的中杆，超过5s可直接断杆，喷射蝠鲼3s内可自信双提，若门票只差这条可选择断杆`,
    },
    // 海洋蟾蜍
    32094: {
      content: `幸福愿景可对3s内的中杆自信双提，蓝鱼如果开了耐心的话是精准提`,
    },
    // 盾齿龙
    32114: {
      content: `我愿称之为最难蓝鱼，鱼识持续45s
                石沙蚕3-5s轻杆提罗斯利特贝以小钓大唯一轻杆提巨魔鱼，进鱼识后仍用石沙蚕钓罗斯利特贝，鱼识相对其他6条更长，但是实际以小钓大有效杆数最多也就2杆，鱼识内钓罗斯利特贝的时候建议频繁拍水，过了5s直接收杆`,
    },
    // 塔尼瓦
    40540: {
      content: `潜底腔棘鱼为唯一!!!，注意鱼识数量需求为3，双提数量仅为2。此外也可以使用青花鱼块代替刺螠，可以省去换饵的时间。鱼识内有长竿，可酌情撒饵。`,
    },
    // 琉璃龙
    40560: {
      content: `捷疾鬼为唯一!!!，双提即可。小判鯙为3s内!!，可提前收杆。小判鯙以小钓大后仅为小判鯙或琉璃龙，对于2分海可在幻海开始时就使用熟练技巧，可保证整个幻海内的以小钓大，对于3分海可等鱼识触发后再开启。提前开启能节省约1s左右的技能后摇，防止鱼识结束卡绝命杆。此外琉璃龙本身咬钩率较高，适合用来刷分。`,
    },
    // 梅泉宝龟
    40600: {
      content: `玄水虾为3-4s的!，无二虾虎为3s内的!，有一定重合区间，但多数情况可放心双提。此外施工门票时可直接使用石蝇幼虫，两种门票鱼竿时不变。梅泉宝龟为5-8s!!!，可酌情提前提钩或者选择撒饵以防止第一竿过长。`,
    },
    // 狱之蟹
    40580: {
      content: `起手使用刺螠，双提3-4.5s的!!，单提4.5+的!!；若先提到巨型真鱿，可切回磷虾，竿时不变。注意尽量不要使用磷虾钓巨型真鱿，用磷虾其竿时会达到8-12s。鱼识内有长竿，可酌情撒饵。在触发鱼识时可直接拍掉最后一个触发鱼，在鱼识结束时最后一竿若为6-7s!!可直接双提。`,
    },
  },
  missionTip: {
    extra:
      "完成任务需要额外的去双提一些非高分鱼去完成任务，完成任务所选择的鱼因人而异，而且需要根据航线和已经钓起来的鱼灵活选择，这里仅列出个人认为可以去专门双提的鱼",
    243: `种类任务：水母or螃蟹X7
          拉诺西亚水母，海荨麻[午]；剪碎手巴塞洛缪[晚]
          数量任务：！34，！！18，！！！6
          ！拉诺西亚水母、蓬松海龙(阴云薄雾不出现)，海荨麻[午]；灵敏舞者、钉牡蛎[早]；珊瑚海龙[午]、剪碎手巴塞洛缪[晚]
          ！！青色章鱼，人鱼发、幽灵鲨；幻纱披风[夜]
          ！！！莫莫拉·莫拉(单)，无赖龙(单)[午]；暗淡鲨(单)(暴雨不出现)，漏斗鲨[午]；守领鳍龙`,
    241: `种类任务：鲨鱼X5
          幽灵鲨，漏斗鲨[午]；铬铁锤头鲨，处刑者[早]
          数量任务：！34，！！11，！！！13
          ！灵敏舞者[早]、钉牡蛎[早]；拉诺西亚水母、蓬松海龙(阴云薄雾不出现)，海荨麻[午]；沉寂者，浮游碟鱼[晚]
          ！！青色章鱼，人鱼发、幽灵鲨
          ！！！暗淡鲨(单)(暴雨不出现)，漏斗鲨[午]；莫莫拉·莫拉(单)，无赖龙(单)[午]；铬铁锤头鲨，处刑者[早]`,
    248: `种类任务：螃蟹X13
          泰坦硬甲蟹；剪碎手巴塞洛缪[夜]；染血抛光蟹，神谕蟹[早]、歼灭者[早]
          数量任务：！31，！！27，！！！5
          ！秘银箱；珊瑚海龙[午] 、剪碎手巴塞洛缪[晚]；染血抛光蟹，神谕蟹[早]、歼灭者[早]
          ！！泰坦硬甲蟹；幻纱披风[晚]；幸福愿景、余韵[晚]
          ！！！喷射蝠鲼；守领鳍龙；石英锤头鲨`,
    250: `种类任务：鲀X13
          秘银箱；沉寂者；蜂巢鱼，鱼酱罐[早晚]、珍珠爆弹[早晚]
          数量任务：！39，！！18，！！！8
          ！秘银箱；沉寂者，浮游碟鱼[晚]；罗斯利特海带、蜂巢鱼，鱼酱罐[早晚]
          ！！泰坦硬甲蟹；珍珠爆弹[早晚]；潘诺普忒斯[早]
          ！！！喷射蝠鲼；铬铁锤头鲨，处刑者[早]`,
  },
  achievementTip: {
    // 八爪旅人
    2563: {
      // 加拉迪翁湾外海
      237: [
        "■【 加拉迪翁湾 】",
        "○通常时（饵：磷虾）",
        " ・ 基本   … ①18秒以后！！普通提钩",
        "              ②20秒以后的！！双重提钩",
        "              ③对！！！普通提钩狙击幻海",
        " ・ 阴, 雾 … 18秒以后！！必定是章鱼 (双重提钩)",
        " ・ 暴雨   … 剩余时间5分钟以后对于！！！不提 ",
      ],
      238: [
        "■【 加拉迪翁湾 】",
        "◎幻海流（饵：磷虾）", //
        " ・ 3秒内的！！双重提钩",
      ],
      // 梅尔托尔海峡南
      239: ["■【 梅尔托尔海峡南 】保存gp自由活动"],
      // 梅尔托尔海峡北
      243: [
        "■【 梅尔托尔海峡北 】",
        "○通常时（饵：石沙蚕）",
        " ・ 只提！！！狙击幻海",
        " ・ 满GP的情况使用拍水以及撒饵",
      ],
      244: [
        "■【 梅尔托尔海峡北 】",
        "◎幻海流（饵：磷虾）",
        " ・ 5秒以后的！！双提→专一(强心剂)[看情况是否专一]→双提",
        " ・ 咬钩较快的！！放弃不提",
      ],
    },
    // 捕鲨人
    2564: {
      // 加拉迪翁湾外海
      237: [
        "■【 加拉迪翁湾 】",
        "○通常时（饵：刺螠）",
        " ・ 基本 … 对所有！！！普通提钩",
        " ・ 暴雨 … 暗淡鲨不出现，全力触发幻海",
        " ・ 幻海结束后对所有的！！！使用双提",
      ],
      238: [
        "■【 加拉迪翁湾 】",
        "◎幻海流（饵：刺螠）",
        " ・ 对所有的！！普通提钩",
        " ・ 3-4s的！！使用双重提钩",
        " ・ 对！！！双提→专一(强心剂)[看情况是否专一]→双提",
      ],
      // 梅尔托尔海峡南
      239: ["■【 梅尔托尔海峡南 】保存gp自由活动"],
      // 罗塔诺海海面
      241: [
        "■【 罗塔诺海 】",
        "○通常时（饵：刺螠）",
        " ・ 基本 … ①21秒内的！！！使用双重提钩",
        "            ②21秒以后的！！！使用普通提钩，看情况选择是否专一",
        " ・ 幻海结束后对所有的！！！使用双重提钩",
      ],
      242: [
        "■【 罗塔诺海 】",
        "◎幻海流（饵：刺螠）",
        " ・ 对所有的！！普通提钩",
        " ・ 对！！！双提→专一(强心剂)[看情况是否专一]→双提",
      ],
    },
    // 水母狂魔
    2565: {
      // 加拉迪翁湾外海
      237: ["■【 加拉迪翁湾 】 保存gp自由活动"],
      // 梅尔托尔海峡南
      239: [
        "■【 梅尔托尔海峡南 】",
        "○通常时（饵：石沙蚕）",
        " ・ 基本    … ①4秒以内的杆使用双重提钩",
        "               ②4-5秒的！使用双重提钩",
        " ・ 微、强风 … 5秒以内杆使用双重提钩",
      ],
      240: [
        "■【 梅尔托尔海峡南 】",
        "◎幻海流（饵：石沙蚕）",
        " ・ 6秒以后的！使用双重提钩",
      ],
      // 罗塔诺海海面
      241: [
        "■【 罗塔诺海 】",
        "○通常时（餌：刺螠）",
        " ・ 只提！！！狙击幻海",
        " ・ 满GP的情况使用拍水 ",
      ],
      242: [
        "■【 罗塔诺海 】",
        "◎幻海流（餌：磷虾）",
        " ・ 6～10秒的！双提→专一(高级强心剂)→双提",
      ],
    },
    // 龙马惊神
    2566: {
      // 加拉迪翁湾外海
      237: ["■【 加拉迪翁湾 】保存gp自由活动"],
      // 梅尔托尔海峡南
      239: [
        "■【 梅尔托尔海峡南 】",
        "○通常时（饵：石沙蚕）",
        " ・ 基本   … ①9-16秒的！使用双重提钩",
        "              ②超过16秒直接收杆",
        " ・ 阴、雾 … 蓬松海龙不出现，请切磷虾全力触发幻海",
      ],
      240: [
        "■【 梅尔托尔海峡南 】",
        "◎幻海流（饵：石沙蚕）",
        " ・ 基本 … 2-5秒的！高级以太药虱以小钓大",
        "以小钓大3-5s的！！双重提钩，5s+的！！普通提钩",
      ],
      // 梅尔托尔海峡北
      243: [
        "■【 梅尔托尔海峡北 】",
        "○通常时（饵：石沙蚕）",
        " ・ 只提！！！狙击幻海",
        " ・ 满GP的情况使用拍水",
      ],
      244: [
        "■【 梅尔托尔海峡北 】",
        "◎幻海流（饵：石沙蚕）",
        " ・ 7秒以后的！双提→专一(强心剂)→双提",
      ],
    },
    // What Did Balloons Do to You?
    2754: {
      241: [
        "■【 罗塔诺海 】",
        "○通常时（饵：石沙蚕）",
        " ・ 基本 … 10-15秒！双重提钩",
      ],
      242: [
        "■【 罗塔诺海 】",
        "◎幻海流（饵：石沙蚕）",
        " ・ 3-5秒的！双重提钩",
      ],
      // Cieldalaes Margin
      246: [
        "■【 谢尔达莱群岛 】",
        "○通常时（饵：石沙蚕）",
        " ・ 基本 … ①9秒内的！双重提钩",
        "            ②对11-17秒的！！！普通提钩狙击幻海",
      ],
      247: [
        "■【 谢尔达莱群岛 】",
        "◎幻海流（饵：石沙蚕）",
        " ・ 3秒内的！双重提钩",
      ],
      // Outer Rothlyt Sound
      250: [
        "■【 罗斯利特湾 】",
        "○通常时（饵：石沙蚕/刺螠）",
        " ・ 基本 … ①6秒内的！普通提钩",
        "            ②12秒(石沙蚕)[17秒(刺螠)]以后的！双重提钩，剩余2分钟以下可以使用专一",
      ],
      251: [
        "■【 罗斯利特湾 】",
        "◎幻海流（饵：磷虾）",
        " ・ 基本 … ①3秒内的！双重提钩",
        "            ②4-5秒的！！普通提钩",
        "            ③5-6秒的！！双重提钩→专一(强心剂)[看情况是否专一]→双重提钩",
      ],
    },
    // What Did Crabs Do to You?
    2755: {
      243: [
        "■【 梅尔托尔海峡北 】",
        "○通常时（饵：磷虾）",
        " ・ 基本 … 8-17秒的！！普通提钩→专一(强心剂)[看情况是否专一]→双提",
        " ・ 可以选择在这一区域不触发幻海",
      ],
      244: [
        "■【 梅尔托尔海峡北 】",
        "◎幻海流（饵：石沙蚕）",
        " ・ 5-6秒的！普通提钩→专一(强心剂)→双提",
        " ・ 对狂野海胆拍水后可以对5-6秒的！直接双提",
      ],
      // Cieldalaes Margin
      246: [
        "■【 谢尔达莱群岛 】",
        "○通常时（饵：磷虾/石沙蚕）",
        " ・ 基本 … ①6-11秒的！！普通提钩",
        "            ②对11-17的！！！普通提钩狙击幻海",
      ],
      247: [
        "■【 谢尔达莱群岛 】",
        "◎幻海流（饵：磷虾）",
        " ・ 3秒内的！！双重提钩",
      ],
      // Open Bloodbrine Sea
      248: [
        "■【 绯汐海 】",
        "○通常时（饵：石沙蚕/磷虾）",
        " ・ 基本 … ①8秒内的！普通提钩",
        "            ②14秒(石沙蚕)[19秒(磷虾)]以后的！双重提钩，剩余2分钟以下可以使用专一",
      ],
      249: [
        "■【 绯汐海 】",
        "◎幻海流（饵：石沙蚕）",
        " ・ 基本 … ①3秒内的！双重提钩",
        "            ②5秒的以后的！双重提钩",
      ],
    },
    // What Did Mantas Do to You?
    2756: {
      // 罗塔诺海海面
      241: ["■【 罗塔诺海 】保存gp自由活动"],
      // 梅尔托尔海峡北
      243: ["■【 梅尔托尔海峡北 】保存gp自由活动"],
      // Cieldalaes Margin
      246: [
        "■【 谢尔达莱群岛 】",
        "○通常时（饵：刺螠/石沙蚕）",
        " ・ 11秒(刺螠)[16秒(石沙蚕)]后以后的！！普通(双重)提钩",
        " ・ 推荐使用石沙蚕对12-19的！！！普通提钩狙击幻海",
        " ・ 幻海出现前适当节省gp",
      ],
      247: [
        "■【 谢尔达莱群岛 】",
        "◎幻海流（饵：刺螠）",
        " ・ 基本 … 3秒内的！！！双重提钩",
        " ・ 日   … 5秒后存在干扰！！！注意不要提错",
      ],
      // Open Bloodbrine Sea
      248: [
        "■【 绯汐海 】",
        "○通常时（饵：磷虾",
        " ・ 只提！！！狙击幻海",
        " ・ 满GP的情况使用拍水、撒饵",
      ],
      249: [
        "■【 绯汐海 】",
        "◎幻海流（饵：磷虾）",
        " ・ 基本 … ①4-5秒的！！普通提钩",
        "            ②5秒以后的！！双重提钩",
      ],
      // Outer Rothlyt Sound
      250: [
        "■【 罗斯利特湾 】",
        "○通常时（饵：刺螠）",
        " ・ 只提！！！狙击幻海",
        " ・ 满GP的情况使用拍水、撒饵",
      ],
      251: [
        "■【 罗斯利特湾 】",
        "◎幻海流（饵：刺螠）",
        " ・ 基本 … ①4-5秒的！！普通提钩",
        "            ②5秒以后的！！双重提钩",
      ],
    },
    // 四海大宝贝
    3267: {
      286: [
        "[ 妖歌海 ]", //
        "通常时(饵：石沙蚕)",
        "・4-7的!使用三(双)重提钩",
      ],
      287: [
        "[ 妖歌海 ]", //
        "幻海流(饵：石沙蚕)",
        "・5秒内的!使用三(双)重提钩",
      ],
      288: [
        "[ 黄金港 ]",
        "通常时(饵：石沙蚕)",
        "・基本 … 15秒以后的!使用三(双)重提钩",
      ],
      289: [
        "[ 黄金港 ]", //
        "幻海流(饵：石沙蚕)",
        "・5秒以后的!使用三(双)重提钩",
      ],
      292: [
        "[ 无二江 ]",
        "通常时(饵：刺螠)",
        "・只提!!!狙击幻海",
        "・满GP的情况使用拍水",
      ],
      293: [
        "[ 无二江 ]", //
        "幻海流(饵：石沙蚕)",
        "・4.5秒后的!使用三(双)重提钩",
      ],
    },
    // 鱿型中队精英
    3268: {
      286: [
        "[ 妖歌海 ]", //
        "通常时(饵：磷虾)",
        "・基本 … 9-18秒的!!使用三(双)重提钩",
      ],
      287: [
        "[ 妖歌海 ]", //
        "幻海流(饵：磷虾)",
        "・对所有的!!使用三(双)重提钩",
      ],
      288: [
        "[ 黄金港 ]", //
        "通常时(饵：刺螠)",
        "・13秒以后的!!使用三(双)重提钩",
      ],
      289: [
        "[ 黄金港 ]", //
        "幻海流(饵：刺螠)",
        "・4秒以后的!!使用三(双)重提钩",
      ],
      290: [
        "[ 红玉海 ]", //
        "通常时(饵：磷虾)",
        "・10-18秒的!!使用三(双)重提钩",
      ],
      291: [
        "[ 红玉海 ]", //
        "幻海流(饵：刺螠)",
        "・3秒后的!!使用三(双)重提钩",
      ],
    },
    // 大虾可重新来过
    3269: {
      286: [
        "[ 妖歌海 ]", //
        "通常时(饵：磷虾)",
        "・基本 … 8-15秒的!使用三(双)重提钩",
      ],
      287: [
        "[ 妖歌海 ]", //
        "幻海流(饵：磷虾)",
        "・ 6-7秒的!使用三(双)重提钩",
      ],
      288: [
        "[ 黄金港 ]", //
        "通常时(饵：石沙蚕)",
        "・基本 …5-9秒的!使用三(双)重提钩",
      ],
      289: [
        "[ 黄金港 ]", //
        "幻海流(饵：石沙蚕)",
        "・ 3秒内的!使用三(双)重提钩",
      ],
      290: [
        "[ 红玉海 ]", //
        "通常时(饵：石沙蚕)",
        "・10-18秒的!使用三(双)重提钩",
      ],
      291: [
        "[ 红玉海 ]", //
        "幻海流(饵：石沙蚕)",
        "・3-4秒的!使用三(双)重提钩",
      ],
      292: [
        "[ 无二江 ]", //
        "通常时(饵：石沙蚕)",
        "・13-24秒的!使用三(双)重提钩",
      ],
      293: [
        "[ 无二江 ]", //
        "幻海流(饵：石沙蚕)",
        "・3-4.5秒的!使用三(双)重提钩",
      ],
    },
  },
};
