import React from "react";

import { Avatar } from "@mui/material";

import { EIcon, EName, IconSourceType } from "../models/base";
import useLocaleName from "../hooks/useLocaleName";

interface RIconProps {
  icon?: EIcon;
  label?: EName | string;
  src?: string;
  source?: IconSourceType;
  type?: "item" | "weather" | "status" | "action";
  size?: "xSmall" | "small" | "medium" | "large";
  dense?: boolean;
}

export const RIcon: React.FC<RIconProps> = ({
  icon,
  src,
  label,
  source = "momola",
  type = "item",
  size = "medium",
  dense = false,
}) => {
  const tip = useLocaleName(label);
  const _src = icon?.toIconUrl({ source }) ?? src;

  const sizeMap = {
    item: {
      xSmall: { width: 24, height: 24 },
      small: { width: 32, height: 32 },
      medium: { width: 40, height: 40 },
      large: { width: 80, height: 80 },
    },
    weather: {
      xSmall: { width: 24, height: 24 },
      small: { width: 32, height: 32 },
      medium: { width: 40, height: 40 },
      large: { width: 80, height: 80 },
    },
    status: {
      xSmall: { width: 12, height: 16 },
      small: { width: 24, height: 32 },
      medium: { width: 36, height: 48 },
      large: { width: 54, height: 72 },
    },
    action: {
      xSmall: { width: 20, height: 20 },
      small: { width: 32, height: 32 },
      medium: { width: 40, height: 40 },
      large: { width: 80, height: 80 },
    },
  };

  const height = sizeMap[type][size]?.height ?? 40;
  const width = sizeMap[type][size]?.width ?? 40;
  return (
    <Avatar
      sx={{ m: dense ? 0 : 0.5, height, width }}
      variant="rounded"
      src={_src}
      imgProps={{ title: tip }}
    >
      {tip
        ?.split(" ")
        ?.map((s) => s[0])
        ?.slice(0, 2)
        ?.join("")}
    </Avatar>
  );
};
