import React from "react";

import { Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

import { EOceanFishingRoute } from "../../models/EOceanFishing";

import { SpotCard } from "./SpotCard";
import { convertTip, getSubSpotTip } from "./spotHelper";
import { IKDTipNotification } from "./IKDTipNotification";

interface IKDRoutePointProps {
  route: EOceanFishingRoute;
}

export const IKDRoutePoint: React.FC<IKDRoutePointProps> = ({ route }) => {
  return (
    <>
      <IKDTipNotification />

      <Grid2 container spacing={2} sx={{ mt: 1 }}>
        {route.spots.map((spot, spotIdx) => {
          const time = route.times[spotIdx];
          return (
            <Grid2 xs={12} md={4} key={spot.id}>
              <Stack spacing={2}>
                <SpotCard
                  spot={spot.mainSpot}
                  variant="point"
                  fishes={spot.mainSpot.pointTip.fishes}
                  tip={convertTip(spot.mainSpot.pointTip.content)}
                />
                <SpotCard
                  spot={spot.subSpot}
                  time={time}
                  variant="point"
                  fishes={getSubSpotTip(spot, time)?.fishes ?? []}
                  tip={convertTip(getSubSpotTip(spot, time)?.content ?? "")}
                />
              </Stack>
            </Grid2>
          );
        })}
      </Grid2>
    </>
  );
};
