import { FishingSpot as FishingSpotData } from "@ffxiv-momola/data";

import { EFishingSpot } from "../models/EFishingSpot";

import PlaceNameRepo from "./placeName";
import FishRepo from "./fishParameter";

type FishingSpot = {
  id: number;
  placeNameId: number;
  items: number[];
};

type FishingSpots = {
  spots: FishingSpot[];
};

class FishingSpotRepository {
  readonly data: Map<number, EFishingSpot>;

  constructor(fishingSpots: FishingSpots) {
    this.data = new Map();
    for (let spot of fishingSpots.spots) {
      const eSpot: EFishingSpot = {
        id: spot.id,
        name: PlaceNameRepo.get(spot.placeNameId)?.name!!,
        fishes: [],
      };
      const fishes = spot.items.map((itemId) => {
        const f = FishRepo.get(itemId);
        f.spot = eSpot;
        return f;
      });
      eSpot.fishes.push(...fishes);
      this.data.set(spot.id, eSpot);
    }
  }

  get(id: number) {
    return this.data.get(id);
  }
}

const FishingSpotRepo = new FishingSpotRepository(FishingSpotData);

export default FishingSpotRepo;
