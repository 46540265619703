import React from "react";

import { Chip } from "@mui/material";

import { ETug } from "../models/EFish";

interface TugIconProps {
  tug: ETug;
}

export const TugIcon: React.FC<TugIconProps> = ({ tug }) => {
  return (
    <Chip
      size="small"
      label={tug.mark}
      color={tug.color}
      sx={{ height: "20px" }}
    />
  );
};
