import React from "react";
import { RIcon } from "../../atomic/RIcon";
import { localMiscIconUrlOf } from "../../utils/icon";
import { useTranslation } from "react-i18next";

const IKDRecommendationIcon: React.FC = () => {
  const { t } = useTranslation();
  return (
    <RIcon
      src={localMiscIconUrlOf("ocean-fishing-score-achievement-40x40")}
      label={t("oceanFishing.filters.type.recommended")}
    />
  );
};

export default IKDRecommendationIcon;
