import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectRouteCount, setRouteCount } from "../../store/oceanFishingSlice";

export const IKDScheduleCountInput: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const count = useAppSelector(selectRouteCount);
  const [tempCount, setTempCount] = React.useState(count.toString());

  const handleCountChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTempCount(event.target.value);
    },
    []
  );

  const handleCountBlur = useCallback(() => {
    const count = parseInt(tempCount);
    if (isFinite(count) && count > 0 && count <= 120) {
      dispatch(setRouteCount(count));
    }
  }, [dispatch, tempCount]);

  const handleCountKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        const count = parseInt(tempCount);
        if (isFinite(count) && count > 0 && count <= 120) {
          dispatch(setRouteCount(count));
        }
      }
    },
    [dispatch, tempCount]
  );

  return (
    <TextField
      label={t("oceanFishing.filters.count")}
      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      value={tempCount}
      onChange={handleCountChange}
      onBlur={handleCountBlur}
      onKeyDown={handleCountKeyDown}
    />
  );
};
