import { chain, concat, find, get, map, uniq } from "lodash";
import { Bedtime, WbSunny, WbTwilight } from "@mui/icons-material";

import {
  IKDContentBonus as IKDContentBonusData,
  IKDFishInfo as IKDFishInfoData,
  IKDRouteGroup as IKDRouteGroupData,
  IKDRouteInfo as IKDRouteInfoData,
  IKDRoutes as IKDRouteData,
  IKDRouteTable as IKDRouteTableData,
  IKDSpot as IKDSpotData,
  IKDSpotInfo as IKDSpotInfoData,
} from "@ffxiv-momola/data";

import { IkdTip } from "./raw/ikdTip";

import {
  EOceanFishingAchievement,
  EOceanFishingAchievementTips,
  EOceanFishingBonus,
  EOceanFishingFish,
  EOceanFishingMainSpot,
  EOceanFishingRoute,
  EOceanFishingRouteGroup,
  EOceanFishingSpot,
  EOceanFishingSpotPointTip,
  EOceanFishingSubSpot,
  EOceanFishingTime,
} from "../models/EOceanFishing";
import { EIcon, EItem, EName } from "../models/base";
import { EFish, EFishingHookset, ETug, ETugType } from "../models/EFish";

import FishingSpotRepo from "./fishingSpot";
import PlaceNameRepo from "./placeName";
import WeatherRepo from "./weather";
import AchievementRepo from "./achievement";
import ItemRepo from "./item";
import { EBait } from "../models/EBait";

const IKD_COMMON_BAITS = [29714, 29715, 29716];

type IKDSpotTime = {
  id: number;
  time: number;
};

type IKDRoute = {
  type: number;
  spots: IKDSpotTime[];
  territoryType: number;
  name: string;
};

type IKDSpot = {
  id: number;
  mainSpot: number;
  subSpot: number;
  placeNameId: number;
};

type IKDSpots = {
  spots: IKDSpot[];
};

type FishPredator = {
  id: number;
  count: number;
};

type IKDFishInfo = {
  id: number;
  itemId: number;
  fishParameterId: number;
  isBigFish: boolean;
  isSpectralFish: boolean;
  isBlueFish: boolean;
  ikdTimes: number[];
  notAvailableWeathers: number[];
  bestCatchPath: number[];
  biteTimeMin: number;
  biteTimeMax: number;
  isBaitUnique: boolean;
  doubleHook: number[];
  tug: string;
  hookset: string;
  points: number;
  ikdContentBonusId: number;
  predators: FishPredator[];
  baitIds: number[];
  biteTimeByBait: object;
};

type IKDRouteInfo = {
  id: number;
  achievementIds: number[];
  missionIds: number[];
  isRecommended: boolean;
};

type IKDSpotInfo = {
  id: number;
  weatherIds: number[];
};

type OceanFishingTime = {
  id: number;
  name: EName;
  icon: any;
};

type IKDRouteGroup = {
  id: string;
  en: string;
  ja: string;
  chs?: string;
};

type MLItem = {
  en: string;
  ja: string;
  chs?: string;
};

type IKDContentBonus = {
  id: number;
  iconId: number;
  bonusRate: number;
  order: number;
  objective: MLItem;
  requirement: MLItem;
  achievementId: number | null;
};

export const OceanFishingTimes: OceanFishingTime[] = [
  {
    id: 0,
    name: new EName({ en: "placeholder" }),
    icon: "",
  }, // placeholder
  {
    id: 1,
    name: new EName({ en: "day", ja: "晨", chs: "早" }),
    icon: WbSunny,
  },
  {
    id: 2,
    name: new EName({ en: "Sunset", ja: "昏", chs: "午" }),
    icon: WbTwilight,
  },
  {
    id: 3,
    name: new EName({ en: "Night", ja: "夜", chs: "晚" }),
    icon: Bedtime,
  },
];

const midRounding = (num: number) => {
  const decimal = Math.floor(num);
  const fraction = num * 10 - decimal * 10;

  if (fraction >= 3 && fraction <= 7) {
    return decimal + 0.5;
  } else if (fraction < 3) {
    return decimal;
  } else {
    return decimal + 1;
  }
};

export const OceanFishingTimeIds = chain(OceanFishingTimes)
  .map((it) => it.id)
  .filter((it) => it > 0)
  .value();

const convert2NoArticle = (name: string) => {
  return name.replace("The ", "").replace("the ", "");
};

const findByNameNoArticle = (nameNoArticle: string) => {
  let name: EName | null = null;
  PlaceNameRepo.data.forEach((value) => {
    if (convert2NoArticle(value.name.en ?? "") === nameNoArticle) {
      name = value.name;
    }
  });
  return name!!;
};

class IKDRepository {
  private readonly routes: Map<number, EOceanFishingRoute>;
  private readonly routeGroups: Map<string, EOceanFishingRouteGroup>;
  private readonly spots: Map<number, EOceanFishingSpot>;
  private readonly fishes: Map<number, EOceanFishingFish>;
  private readonly bonuses: Map<number, EOceanFishingBonus>;
  readonly allRouteAchievements: EOceanFishingAchievement[];

  get allRouteGroups() {
    return Array.from(this.routeGroups.values());
  }

  get allRoutes() {
    return Array.from(this.routes.values());
  }

  get allBlueFishes() {
    return Array.from(this.fishes.values()).filter((f) => f.isBlueFish);
  }

  get allSpots() {
    return Array.from(this.spots.values());
  }

  constructor(
    routeGroups: IKDRouteGroup[],
    routes: IKDRoute[],
    ikdSpots: IKDSpots,
    routeTables: number[][],
    ikdFishInfo: IKDFishInfo[],
    ikdRouteInfo: IKDRouteInfo[],
    ikdSpotInfo: IKDSpotInfo[],
    ikdContentBonuses: IKDContentBonus[]
  ) {
    //region assemble content bonuses
    this.bonuses = new Map();
    ikdContentBonuses.forEach((bonus) => {
      this.bonuses.set(bonus.id, {
        id: bonus.id,
        objective: new EName({
          chs: bonus.objective.chs,
          en: bonus.objective.en,
          ja: bonus.objective.ja,
        }),
        requirement: new EName({
          chs: bonus.requirement.chs,
          en: bonus.requirement.en,
          ja: bonus.requirement.ja,
        }),
        bonusRate: bonus.bonusRate,
        icon: new EIcon(bonus.iconId),
        order: bonus.order,
        achievement: bonus.achievementId
          ? AchievementRepo.get(bonus.achievementId)
          : null,
      });
    });
    //endregion

    //region assemble spots and fishes
    this.spots = new Map();
    this.fishes = new Map();
    ikdSpots.spots.forEach((ikdSpotData) => {
      const mainSpot = FishingSpotRepo.get(ikdSpotData.mainSpot)!!;
      const subSpot = FishingSpotRepo.get(ikdSpotData.subSpot)!!;

      const mainSpotInfo = ikdSpotInfo.find(
        (it) => it.id === ikdSpotData.mainSpot
      )!!;
      const ikdSpot: EOceanFishingSpot = {
        id: ikdSpotData.id,
        name: PlaceNameRepo.get(ikdSpotData.placeNameId)?.name!!,
        mainSpot: new EOceanFishingMainSpot(
          mainSpot,
          mainSpotInfo.weatherIds.map((id) => WeatherRepo.get(id))
        ),
        subSpot: new EOceanFishingSubSpot(subSpot),
      };
      const assembleFish = (f: EFish) => {
        const fishInfo = find(ikdFishInfo, {
          itemId: f.id,
        })!!;
        const tip = get(IkdTip.fishTip, f.id, { content: "" });
        tip.content = tip.content
          .split("\n")
          .map((line) => line.trim())
          .join("\n");
        return new EOceanFishingFish(
          f,
          ikdSpot,
          fishInfo.isBigFish,
          fishInfo.isSpectralFish,
          fishInfo.isBlueFish,
          fishInfo.ikdTimes.map((time) => OceanFishingTimes[time]),
          fishInfo.notAvailableWeathers.map((w) => WeatherRepo.get(w)!!),
          midRounding(fishInfo.biteTimeMin),
          midRounding(fishInfo.biteTimeMax),
          fishInfo.isBaitUnique,
          fishInfo.doubleHook,
          ETug.fromTugType(fishInfo.tug as ETugType),
          fishInfo.hookset as EFishingHookset,
          fishInfo.points,
          tip ? [tip] : [],
          this.bonuses.get(fishInfo.ikdContentBonusId),
          [],
          [],
          chain(fishInfo.biteTimeByBait as { [key: string]: number[] })
            .entries()
            .map(([itemId, [min, max]]) => {
              return {
                bait: ItemRepo.get(+itemId) ?? this.fishes.get(+itemId),
                biteTimeMin: midRounding(min),
                biteTimeMax: max ? midRounding(max) : midRounding(min),
              };
            })
            .sortBy((it) => {
              const sortVals = [];
              sortVals.push(it.bait.type === "bait" ? 0 : 1);
              sortVals.push(IKD_COMMON_BAITS.includes(it.bait.id) ? 0 : 1);
              sortVals.push(it.bait.id);
              return sortVals;
            })
            .value()
        );
      };
      const mainSpotFishes = mainSpot.fishes.map(assembleFish);
      const subSpotFishes = subSpot.fishes.map(assembleFish);
      ikdSpot.mainSpot.fishes.push(...mainSpotFishes);
      ikdSpot.mainSpot.pointTip.content = get(
        IkdTip,
        `pointTip.${mainSpot.id}.all`,
        ""
      );
      ikdSpot.mainSpot.pointTip.fishes.push(
        ...mainSpotFishes.filter((f) => f.isBigFish)
      );

      ikdSpot.subSpot.fishes.push(...subSpotFishes);

      OceanFishingTimes.filter((it) => it.id > 0).forEach((time) => {
        const fishIds = uniq(
          concat(
            get(IkdTip, `pointTip.${subSpot.id}.${time.id}.fishList`, []),
            subSpotFishes.find(
              (f) => f.isBlueFish && f.ikdTimes.some((it) => it.id === time.id)
            )?.id ?? []
          )
        );
        const fishes = fishIds.map(
          (id: number) => subSpotFishes.find((f) => f.id === id)!!
        );
        const tip = {
          content: get(IkdTip, `pointTip.${subSpot.id}.${time.id}.content`, ""),
          fishes: fishes,
        } as EOceanFishingSpotPointTip;
        ikdSpot.subSpot.pointTips.set(time.id, tip);
      });

      [...mainSpotFishes, ...subSpotFishes].forEach((f) =>
        this.fishes.set(f.id, f)
      );
      this.spots.set(ikdSpotData.id, ikdSpot);
    });
    ikdFishInfo.forEach((fishInfo) => {
      const fish = this.fishes.get(fishInfo.itemId)!!;
      fish.bestCatchPath = fishInfo.bestCatchPath.map((itemId, idx) => {
        if (idx === 0) {
          return ItemRepo.getBait(itemId);
        }
        return this.fishes.get(itemId)!!;
      });
      fish.exampleBaits = fishInfo.baitIds.map((id) => {
        const item: EItem = ItemRepo.get(id);
        return item.type === "fish" ? this.fishes.get(id)!! : (item as EBait);
      });
      fish.predators = fishInfo.predators.map((predator) => {
        return {
          fish: this.fishes.get(predator.id)!!,
          count: predator.count,
        };
      });
    });

    //endregion

    this.routes = new Map();
    this.assembleRoutes(routes, ikdRouteInfo);

    //region assemble route groups
    this.routeGroups = new Map();
    routeGroups.forEach((routeGroup, idx) => {
      this.routeGroups.set(routeGroup.id, {
        id: routeGroup.id,
        name: new EName({
          en: routeGroup.en,
          ja: routeGroup.ja,
          chs: routeGroup.chs,
        }),
        routes: map(routeTables[idx], (it) => this.routes.get(it)!!),
        routeTypes: routeTables[idx],
      });
    });
    //endregion

    //region assemble achievements
    this.allRouteAchievements = [];
    for (let route of this.allRoutes) {
      this.allRouteAchievements.push(...route.achievements);
    }
    // union by achievement id
    this.allRouteAchievements = chain(this.allRouteAchievements)
      .uniqBy((it) => it.achievement.id)
      .sortBy((it) => it.achievement.id)
      .value();
    //endregion
  }

  private assembleAchievements(route: IKDRoute, routeInfo: IKDRouteInfo) {
    return routeInfo.achievementIds.map((achievementId) => {
      const tips: EOceanFishingAchievementTips = chain(route.spots)
        .map(({ id: spotId }) => {
          const spot = this.spots.get(spotId)!!;
          const getSpotTip = (id: number) => {
            return concat(
              [],
              get(IkdTip, `achievementTip.${achievementId}.${id}`, [])
            ).join("\n");
          };

          return [spot.mainSpot.id, spot.subSpot.id].map((id) => {
            return {
              content: getSpotTip(id),
              spotId: spotId,
              mainSubSpotId: id,
              achievementId: achievementId,
            };
          });
        })
        .flatten()
        .keyBy("mainSubSpotId")
        .mapValues((it) => {
          const spot = this.spots.get(it.spotId)!!;
          const mainSubSpot =
            spot.mainSpot.id === it.mainSubSpotId
              ? spot.mainSpot
              : spot.subSpot;
          const fishes = mainSubSpot.fishes.filter((f) => {
            return (
              f.bonus?.achievement?.id === achievementId ||
              (mainSubSpot instanceof EOceanFishingMainSpot && f.isSpectralFish)
            );
          });
          return { content: it.content, fishes: fishes };
        })
        .value();
      return {
        achievement: AchievementRepo.get(achievementId),
        tips: tips,
      };
    });
  }

  private assembleRoutes(routes: IKDRoute[], ikdRouteInfo: IKDRouteInfo[]) {
    const getBlueFishes = (
      spots: EOceanFishingSpot[],
      times: EOceanFishingTime[]
    ) => {
      return spots.flatMap((spot, i) => {
        return spot.subSpot.fishes.filter((f) => {
          // if (f.isBlueFish) console.log(f.ikdTimes, times[i]);
          return f.isBlueFish && f.ikdTimes.some((it) => it.id === times[i].id);
        });
      });
    };

    for (let route of routes) {
      const spots = map(route.spots, (it) => this.spots.get(it.id)!!);
      const routeName = findByNameNoArticle(route.name);
      const times = map(route.spots, (it) => ({
        id: it.time,
        name: OceanFishingTimes[it.time].name,
        icon: OceanFishingTimes[it.time].icon,
      }));
      const routeInfo = ikdRouteInfo.find((r) => r.id === route.type)!!;
      this.routes.set(route.type, {
        id: route.type,
        type: route.type,
        spots: spots,
        times: times,
        time: times[0],
        territoryType: route.territoryType,
        name: new EName({
          en: route.name,
          ja: routeName?.ja,
          chs: routeName?.chs,
        }),
        availableBlueFishes: getBlueFishes(spots, times),
        achievements: this.assembleAchievements(route, routeInfo),
        isRecommended: routeInfo.isRecommended,
      });
    }
  }

  getRoute(id: number) {
    return this.routes.get(id)!!;
  }

  getRouteGroup(id: string) {
    return this.routeGroups.get(id)!!;
  }

  getSpot(id: number) {
    return this.spots.get(id)!!;
  }

  getTime(id: number) {
    return find(OceanFishingTimes, { id })!!;
  }
}

const IKDRepo = new IKDRepository(
  IKDRouteGroupData,
  IKDRouteData.routes,
  IKDSpotData,
  IKDRouteTableData.routeTable,
  IKDFishInfoData,
  IKDRouteInfoData,
  IKDSpotInfoData,
  IKDContentBonusData
);

export default IKDRepo;
