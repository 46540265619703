import _ from "lodash";
import { Store } from "redux";

import { getCountDown } from "../../services/mjiAnimalService";
import mjiService from "../../services/mjiService";
import { CountDownCache, setCountDown } from "../../store/animalsSlice";

import { CountDownWorker } from ".";

const calcCountDown = (store: Store) => {
  // calc if we need update animal list
  const animalList = mjiService.getAnimalList();

  const t: CountDownCache = {};
  const territoryTypeId = 1055;
  for (const animal of animalList) {
    t[animal.id] = getCountDown(animal, territoryTypeId);
  }
  const prevCountDown: CountDownCache =
    store.getState().animals.countDown ?? {};
  let needUpdate = _.isEqual(prevCountDown, {});
  for (const prevCountDownKey in prevCountDown) {
    const prevCountDownEntry = prevCountDown[prevCountDownKey];
    if (_.isEqual(prevCountDownEntry, t[prevCountDownKey])) {
      t[prevCountDownKey] = prevCountDownEntry;
    } else {
      needUpdate = true;
      console.debug("really update", prevCountDownKey);
    }
  }
  if (needUpdate) {
    store.dispatch(setCountDown(t));
  }
};

const animalWorker: CountDownWorker = { calcCountDown };
export default animalWorker;
