import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import DrawerHeader from "../molecules/DrawerHeader";
import { DRAWER_WIDTH } from "./SettingDrawer";

export interface NavOption {
  title: string;
  icon: ReactNode;
  link: string;
}

interface NavDrawerProps {
  open: boolean;
  options: NavOption[];
}

const NavDrawer: React.FC<NavDrawerProps> = ({ open, options }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const goTo = (link: string) => {
    navigate(link);
  };

  return (
    <>
      {open && (
        <Drawer
          variant="permanent"
          sx={{
            zIndex: theme.zIndex.appBar - 1,
          }}
          PaperProps={
            isMobile
              ? {
                  sx: {
                    minWidth: `${DRAWER_WIDTH}px`,
                  },
                }
              : {}
          }
        >
          <DrawerHeader />
          <Divider />
          <List>
            {options.map(({ title, icon, link }) => (
              <ListItem key={title} disablePadding onClick={() => goTo(link)}>
                <Tooltip
                  title={title}
                  placement={"right"}
                  disableHoverListener={isMobile}
                >
                  <ListItemButton>
                    <ListItemIcon
                      sx={
                        isMobile
                          ? {}
                          : {
                              minWidth: 0,
                              justifyContent: "center",
                            }
                      }
                    >
                      {icon}
                    </ListItemIcon>
                    {isMobile && <ListItemText primary={title} />}
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </Drawer>
      )}
    </>
  );
};

export default NavDrawer;
