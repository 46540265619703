import { Store } from "redux";
import { forEach } from "lodash";

import { setNow } from "./globalSlice";

import WORKERS, { CountDownWorker } from "../core/workers";

const enableWorker = (store: Store) => {
  setInterval(() => {
    store.dispatch(setNow(Date.now()));
    // run all workers in WORKERS
    forEach(WORKERS, (worker: CountDownWorker) => {
      worker.calcCountDown && worker.calcCountDown(store);
    });
  }, 500);
};

export default enableWorker;
