import * as territoryTypeDict from './raw/territoryType.json';
import * as weatherRatesDict from './raw/weatherRates.json';
import { ETerritoryType } from '../models/base';

type TerritoryTypeDict = {
  [key: number]: TerritoryType;
};

type TerritoryType = {
  id: number;
  regionPlaceName: number;
  placeName: number;
  weatherRate: number;
};

type WeatherRateDict = {
  [key: number]: WeatherRate;
};

type WeatherRate = {
  id: number;
  weatherRates: number[][];
};

class TerritoryTypeRepository {
  readonly data: Map<number, ETerritoryType>;

  constructor(dict: TerritoryTypeDict, weatherRatesDict: WeatherRateDict) {
    this.data = new Map();
    for (let key in dict) {
      if (+key > 0) {
        const entry = dict[key];
        this.data.set(entry.id, {
          id: entry.id,
          weatherRates: weatherRatesDict[entry.weatherRate]?.weatherRates || [],
        } as ETerritoryType);
      }
    }
  }

  get(id: number) {
    return this.data.get(id);
  }
}

const ETerritoryTypeRepo = new TerritoryTypeRepository(territoryTypeDict, weatherRatesDict);

export default ETerritoryTypeRepo;
