import React from "react";
import { useTranslation } from "react-i18next";

import { Alert, Container, Paper } from "@mui/material";

import { Markdown } from "../../atomic/Markdown";

import { IKDScheduleFilters } from "./IKDScheduleFilters";
import { IKDScheduleList } from "./IKDScheduleList";

const notificationMarkdown = `
- 点击航线可以查看航线详情
`;

const IKDSchedule: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        px: 1,
      }}
    >
      <Alert sx={{ mx: -1, mb: 1, borderRadius: 0 }} severity="info">
        {t("general.wip")}
        <Markdown>{notificationMarkdown}</Markdown>
      </Alert>
      <Paper sx={{ mt: 1 }}>
        <IKDScheduleFilters />
      </Paper>
      <Paper sx={{ mt: 1 }}>
        <IKDScheduleList />
      </Paper>
    </Container>
  );
};

export default IKDSchedule;
