// https://cafemaker.wakingsands.com
export const XIVAPIBase = "https://xivapi.com";
export const MOMOLA_STATIC_BASE =
  "https://static.ffmomola.com/pastry-fish-static-files";
export const LOCAL_STATIC_BASE = process.env.PUBLIC_URL;

export const GARLAND_CN_ICON_BASE = "https://garlandtools.org/files/icons";

export const STARLIGHT_CELEBRATION = {
  region: {
    CN: {
      startTime: 1640066400000,
      endTime: 1641276000000,
    },
    Global: {
      startTime: 1639641600000,
      endTime: 1640962740000,
    },
  },
  // startTime: 1640066400000,
  // endTime: 1641276000000,
  weather: 15,
  spots: [35, 36, 104, 90, 91, 81, 100, 105, 106, 197, 198],
  territories: [129, 128, 133, 132, 339, 340, 341, 641],
  fish: [4905, 7713, 7919, 7923, 7917, 7701],
};

export const CN_VERSION = 6.4;

export const FISH_WINDOW_FORECAST_N = 10;
