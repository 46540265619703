import { useAppSelector } from "../store/hooks";
import { selectGameDataLocale } from "../store/settingsSlice";
import { EName } from "../models/base";

const useLocaleName = (name?: EName | string) => {
  const locale = useAppSelector(selectGameDataLocale);
  return typeof name === "string" ? name : name?.getLocaleName(locale) ?? "";
};

export default useLocaleName;
