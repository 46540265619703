import React from "react";
import {
  Box,
  Icon,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useAppSelector } from "../../store/hooks";
import { selectGameDataLocale } from "../../store/settingsSlice";
import { RouteInfo } from "../../core/oceanFishing";
import { RIcon } from "../../atomic/RIcon";

import IKDRecommendationIcon from "./IKDRecommedationIcon";

export type RouteInfoUI = RouteInfo & { formattedTime: string };

interface OceanFishingRowProps {
  route: RouteInfoUI;
  onClick?: () => void;
}

// OceanFishingRow to show a single route
export const IKDScheduleRow: React.FC<OceanFishingRowProps> = ({
  route,
  onClick,
}) => {
  const theme = useTheme();
  const locale = useAppSelector(selectGameDataLocale);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isDev = process.env.NODE_ENV === "development";

  return (
    <ListItem
      disablePadding
      disableGutters
      alignItems="flex-start"
      onClick={onClick}
    >
      <ListItemButton>
        <ListItemText>
          <Grid container spacing={2}>
            <Grid xs={6} sm={2} sx={{ display: "flex", alignItems: "center" }}>
              <Typography align="right" width="100%">
                {route.formattedTime}
              </Typography>
            </Grid>
            <Grid xs={6} sm={3} sx={{ display: "flex", alignItems: "center" }}>
              {route.isRecommended && <IKDRecommendationIcon />}
              {route.achievements.map(({ achievement }) => {
                return (
                  <RIcon
                    key={"icon-" + achievement.icon.id}
                    src={achievement.icon.toIconUrl({ source: "momola" })}
                    label={achievement.name}
                  ></RIcon>
                );
              })}
              {route.availableBlueFishes.map((fish) => {
                return (
                  <RIcon
                    key={"icon-" + fish.icon.id}
                    src={fish.icon.toIconUrl({ source: "momola" })}
                    label={fish.name}
                  ></RIcon>
                );
              })}
            </Grid>
            {!isMobile && (
              <>
                <Grid
                  xs={6}
                  sm={2}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <span>
                    {(isDev ? route.id + " " : "") +
                      route.name.getLocaleName(locale)}
                  </span>
                  <Icon component={route.time.icon}></Icon>
                </Grid>
                <Grid
                  xs={6}
                  sm={5}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {route.spots.map((spot, idx) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      key={spot.id}
                    >
                      {idx > 0 && <ArrowForwardIcon />}
                      <span>{spot.mainSpot.name.getLocaleName(locale)}</span>
                      <Icon component={route.times[idx].icon}></Icon>
                    </Box>
                  ))}
                </Grid>
              </>
            )}
          </Grid>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};
