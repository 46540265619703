import { get } from "lodash";
import {
  GARLAND_CN_ICON_BASE,
  LOCAL_STATIC_BASE,
  MOMOLA_STATIC_BASE,
} from "../data/env/constants";
import { padding } from "../utils/icon";

type EItemType = "item" | "bait" | "fish" | "island-item";

export class EItem {
  id: number;
  name: EName;
  icon: EIcon;
  type: EItemType;

  get itemId() {
    return this.id;
  }

  constructor(id: number, name: EName, icon: EIcon, type: EItemType = "item") {
    this.id = id;
    this.name = name;
    this.icon = icon;
    this.type = type;
  }
}

export type GarlandIconType = "item" | "achievement";
export type LocalIconType = "item" | "animal";
export type IconSourceType = "local" | "momola" | "garland";

export const ICON_ID = {
  UNKNOWN_FISH_RED: 60034,
  FISHER_INTUITION: 11101,
  ACTION_PRECISION_HOOKSET: 1116,
  ACTION_POWERFUL_HOOKSET: 1115,
};

export class EIcon {
  id: number;

  constructor(id: number) {
    this.id = id;
  }

  static of(id: number) {
    return new EIcon(id);
  }

  static Empty = EIcon.of(ICON_ID.UNKNOWN_FISH_RED);

  toIconUrl({
    source = "momola",
    type = "item",
  }: { source?: IconSourceType; type?: GarlandIconType | LocalIconType } = {}) {
    switch (source) {
      case "momola":
        return this.toMomolaStaticUrl();
      case "garland":
        return this.toGarlandIconUrl(type as GarlandIconType);
      case "local":
      default:
        return this.toLocalIconUrl(type as LocalIconType);
    }
  }

  toMomolaStaticUrl() {
    return `${MOMOLA_STATIC_BASE}/img/item/${padding(this.id)}_hr1.webp`;
  }

  toLocalIconUrl(type: LocalIconType) {
    return EIcon.localIconUrlOf(this.id, type);
  }

  static localIconUrlOf(id: number, type: LocalIconType) {
    return `${LOCAL_STATIC_BASE}/img/${type}/${padding(id)}_hr1.webp`;
  }

  toGarlandIconUrl(type: GarlandIconType) {
    // https://garlandtools.cn/files/icons/item/27011.png
    return `${GARLAND_CN_ICON_BASE}/${type}/${this.id}.png`;
  }
}

export type Locale = "chs" | "en" | "ja";

export class EName {
  chs?: string;
  en?: string;
  ja?: string;

  static Empty = new EName();

  constructor({
    chs,
    en,
    ja,
  }: { chs?: string; en?: string; ja?: string } = {}) {
    this.chs = chs;
    this.en = en;
    this.ja = ja;
  }

  getLocaleName(locale: Locale, { chsDefault = "en" } = {}): string {
    switch (locale) {
      case "chs":
        return this.chs ?? get(this, chsDefault, "");
      case "en":
        return this.en ?? "";
      case "ja":
        return this.ja ?? "";
      default:
        return "";
    }
  }
}

export const EMPTY_NAME = new EName();

export interface ETerritoryType {
  id: number;
  weatherRates: number[][];
}

export type Position = {
  x: number;
  y: number;
};

export type CountDownType = "Available" | "Waiting" | "AllAvailable";
