import React from "react";
import {
  EOceanFishingSpot,
  EOceanFishingTime,
} from "../../models/EOceanFishing";
import Typography from "@mui/material/Typography";

export const convertTip = (tip?: string) => {
  if (!tip) return null;

  return (
    <Typography variant="body1" key={tip} style={{ whiteSpace: "pre-wrap" }}>
      {tip}
    </Typography>
  );
};

export const getSubSpotTip = (
  spot: EOceanFishingSpot,
  time: EOceanFishingTime
) => {
  return spot.subSpot.pointTips.get(time.id);
};
