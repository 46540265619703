import React, { Fragment, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { repeat } from "lodash";
import copy from "copy-to-clipboard";

import {
  Box,
  Chip,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListSubheader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  EOceanFishingFish,
  EOceanFishingSpotType,
  EOceanFishingTime,
} from "../../models/EOceanFishing";
import { useAppSelector } from "../../store/hooks";
import { selectGameDataLocale } from "../../store/settingsSlice";
import { RIcon } from "../../atomic/RIcon";
import { EIcon, ICON_ID, Locale } from "../../models/base";
import ListItemText from "@mui/material/ListItemText";
import { BaitIcon } from "../../molecules/BaitIcon";
import { TugIcon } from "../../molecules/TugIcon";
import { HooksetIcon } from "../../molecules/HooksetIcon";
import { biteTimeDisplayOf } from "../../utils/oceanFishing";

interface IKDRouteFishTableProps {
  name: string | ReactNode;
  fishes: EOceanFishingFish[];
  type: EOceanFishingSpotType;
  time?: EOceanFishingTime;
}

export const IKDFishTable: React.FC<IKDRouteFishTableProps> = ({
  name,
  fishes,
  type,
  time,
}) => {
  const { t } = useTranslation();
  const locale = useAppSelector(selectGameDataLocale);
  const isDev = process.env.NODE_ENV === "development";

  const isIkdTimeAvailable = (
    times: EOceanFishingTime[],
    time?: EOceanFishingTime
  ) => {
    return !time || times.map((it) => it.id).includes(time.id);
  };

  const baitCellOf = (fish: EOceanFishingFish) => {
    const predatorsOf = (fish: EOceanFishingFish) => {
      return (
        <>
          {fish.predators.map((predator) => {
            return (
              <Fragment key={predator.fish.id}>
                <RIcon
                  key={predator.fish.id}
                  icon={predator.fish.icon}
                  label={predator.fish.name}
                  size="small"
                />
                <Typography variant="body2">X</Typography>
                <Chip
                  size="small"
                  label={predator.count}
                  color="warning"
                  sx={{ ml: 0.5 }}
                />
              </Fragment>
            );
          })}
          {fish.predators.length > 0 && (
            <RIcon
              icon={EIcon.of(ICON_ID.FISHER_INTUITION)}
              label={t("oceanFishing.table.fish.cell.fisherIntuition")}
              size="small"
              type="status"
            />
          )}
        </>
      );
    };

    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {predatorsOf(fish)}
        {fish.exampleBaits.map((bait, idx) => {
          return (
            <Fragment key={bait.id}>
              {idx > 0 ? (
                <Typography variant="body2">
                  {t("oceanFishing.table.fish.cell.or")}
                </Typography>
              ) : null}
              <BaitIcon bait={bait} uniqueType={fish.baitUniqueType} />
            </Fragment>
          );
        })}
      </Box>
    );
  };

  const ikdTimeIconsOf = React.useCallback(
    (
      ikdTimes: EOceanFishingTime[],
      locale: Locale,
      spotIkdTime?: EOceanFishingTime
    ) => {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {ikdTimes.map((it) => {
            const icon = it.icon;
            return (
              <div key={it.id} title={it.name.getLocaleName(locale)}>
                <Icon sx={{ mr: 1 }} component={icon}></Icon>
              </div>
            );
          })}
          {!isIkdTimeAvailable(ikdTimes, spotIkdTime) && (
            <Typography variant="body2">
              {t("oceanFishing.table.fish.cell.notAvailableInSpotTime")}
            </Typography>
          )}
        </Box>
      );
    },
    [t]
  );

  return (
    <>
      <ListSubheader>{name}</ListSubheader>
      <TableContainer component={Paper}>
        <Table size="small" sx={{ minWidth: 1024 }}>
          <TableHead>
            <TableRow>
              <TableCell width="15%">
                {t("oceanFishing.table.fish.header.name")}
              </TableCell>
              <TableCell align="center" width="15%">
                {t("oceanFishing.table.fish.header.bait")}
              </TableCell>
              <TableCell align="center" width="5%">
                {t("oceanFishing.table.fish.header.tug")}
              </TableCell>
              <TableCell align="center" width="10%">
                {t("oceanFishing.table.fish.header.biteTime")}
              </TableCell>
              <TableCell align="center" width="5%">
                {t("oceanFishing.table.fish.header.score")}
              </TableCell>
              <TableCell align="center" width="5%">
                {t("oceanFishing.table.fish.header.doubleHook")}
              </TableCell>
              <TableCell align="center" width="5%">
                {t("oceanFishing.table.fish.header.tripleHook")}
              </TableCell>
              {type === "normal" && (
                <TableCell align="center" width="15%">
                  {t("oceanFishing.table.fish.header.weather")}
                </TableCell>
              )}
              {type === "spectral" && (
                <TableCell align="center" width="15%">
                  {t("oceanFishing.table.fish.header.ikdTime")}
                </TableCell>
              )}
              <TableCell align="center" width="5%">
                {t("oceanFishing.table.fish.header.type")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fishes.map((fish) => (
              <TableRow
                key={fish.id}
                sx={{
                  opacity: isIkdTimeAvailable(fish.ikdTimes, time) ? 1 : 0.5,
                }}
                hover={isIkdTimeAvailable(fish.ikdTimes, time)}
              >
                <TableCell>
                  <Box sx={{ display: "flex" }}>
                    <RIcon icon={fish.icon} label={fish.name} size="small" />
                    <div>
                      <Typography
                        variant="body2"
                        onDoubleClick={() => copy(fish.id.toString())}
                      >
                        {`${fish.name.getLocaleName(locale)}${
                          isDev ? `- ${fish.id}` : ""
                        }`}
                      </Typography>
                      <Typography variant="body2">
                        {repeat("★", fish.fishParameter.oceanStars)}
                      </Typography>
                    </div>
                  </Box>
                </TableCell>
                <TableCell align="center">{baitCellOf(fish)}</TableCell>
                <TableCell align="center">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TugIcon tug={fish.tug} />
                    {fish.tug.type === "heavy" && (
                      <HooksetIcon hookset={fish.hookset} />
                    )}
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Tooltip
                    placement="right-start"
                    title={
                      <>
                        <List>
                          {fish.allBaitsWithBiteTime.map((b, idx) => {
                            return (
                              <Fragment key={b.bait.id}>
                                {idx > 0 && <Divider component="li" />}
                                <ListItem>
                                  <ListItemAvatar>
                                    <RIcon
                                      icon={b.bait.icon}
                                      label={b.bait.name}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={b.bait.name.getLocaleName(locale)}
                                    secondary={biteTimeDisplayOf(
                                      b.biteTimeMin,
                                      b.biteTimeMax
                                    )}
                                  />
                                </ListItem>
                              </Fragment>
                            );
                          })}
                        </List>
                      </>
                    }
                  >
                    <Typography>
                      {biteTimeDisplayOf(fish.biteTimeMin, fish.biteTimeMax)}
                    </Typography>
                  </Tooltip>
                </TableCell>

                <TableCell align="center">
                  <Typography>{fish.points}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{fish.doubleHook.join(" - ")}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{fish.tripleHook.join(" - ")}</Typography>
                </TableCell>
                {type === "normal" && fish.notAvailableWeathers.length === 0 && (
                  <TableCell align="center">
                    <Typography variant="body2">
                      {t("oceanFishing.table.fish.cell.weatherAllAvailable")}
                    </Typography>
                  </TableCell>
                )}
                {type === "normal" && fish.notAvailableWeathers.length > 0 && (
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="body2">
                        {t("oceanFishing.table.fish.cell.at")}
                      </Typography>
                      {fish.notAvailableWeathers.map((weather) => {
                        return (
                          <RIcon
                            key={weather.id}
                            icon={weather.icon}
                            label={weather.name}
                            type="weather"
                            size="small"
                            dense
                          />
                        );
                      })}
                      <Typography variant="body2">
                        {t("oceanFishing.table.fish.cell.notAvailable")}
                      </Typography>
                    </Box>
                  </TableCell>
                )}
                {type === "spectral" && (
                  <TableCell align="center">
                    {fish.ikdTimes.length === 3 && (
                      <Typography variant="body2">
                        {t("oceanFishing.table.fish.cell.ikdTimeAllAvailable")}
                      </Typography>
                    )}
                    {fish.ikdTimes.length < 3 &&
                      ikdTimeIconsOf(fish.ikdTimes, locale, time)}
                  </TableCell>
                )}

                <TableCell align="center">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {fish.bonus ? (
                      <RIcon
                        icon={fish.bonus.icon}
                        label={fish.bonus.objective.getLocaleName(locale)}
                        size="small"
                      />
                    ) : null}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
