import EMJIAnimalRepo from "../data/mjiAnimal";
import ETerritoryTypeRepo from "../data/territoryType";

const MjiService = {
  getAnimalList() {
    return EMJIAnimalRepo.getAll();
  },
  getWeatherRates(territoryTypeId: number) {
    return ETerritoryTypeRepo.get(territoryTypeId)?.weatherRates || [];
  },
  getAnimal(animalId: number) {
    return EMJIAnimalRepo.get(animalId);
  },
};

export default MjiService;
