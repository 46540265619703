import { isEmpty, isNil, isArray } from "lodash";

import React, { ReactNode } from "react";

import {
  FormControl,
  FormLabel,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

interface RToolbarProps {
  label: string;
  value: any;
  options: {
    id: any;
    name: string | ReactNode;
  }[];
  exclusive?: boolean;
  mandatory?: boolean;
  onChange: (v: any) => void;
}

export const RToggleButtons: React.FC<RToolbarProps> = ({
  label,
  value,
  options,
  exclusive = false,
  mandatory = false,
  onChange,
}) => {
  return (
    <FormControl>
      <FormLabel
        sx={{
          ml: 0.25,
          mb: 1,
        }}
      >
        {label}
      </FormLabel>
      <ToggleButtonGroup
        value={value}
        onChange={(_, v) => {
          if (mandatory && (isNil(v) || (isArray(v) && isEmpty(v)))) {
            return;
          }
          onChange(v);
        }}
        exclusive={exclusive}
        color="primary"
        size="small"
      >
        {options.map((it) => (
          <ToggleButton value={it.id} key={it.id}>
            {it.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </FormControl>
  );
};
