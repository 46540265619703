import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import App from '../App';
import React from 'react';
import { useAppSelector } from '../store/hooks';
import { selectThemeMode } from '../store/settingsSlice';
import getDesignTokens from './palette';

const ThemeApp: React.FC = () => {
  const [systemMode, setSystemMode] = React.useState<'light' | 'dark'>('dark');

  React.useEffect(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (event) => {
        const colorScheme = event.matches ? 'dark' : 'light';
        console.log(colorScheme); // "dark" or "light"
        setSystemMode(colorScheme);
      });
  }, []);

  const themeMode = useAppSelector(selectThemeMode);

  const theme = React.useMemo(
    () =>
      createTheme(getDesignTokens(themeMode === 'auto' ? systemMode : themeMode)),
    [systemMode, themeMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
};

export default ThemeApp;
