import React, { lazy } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";

import { Avatar, Container, useMediaQuery, useTheme } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import CatchingPokemonIcon from "@mui/icons-material/CatchingPokemon";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";

import { LOCAL_STATIC_BASE } from "./data/env/constants";
import Loader from "./molecules/Loader";
import Main from "./molecules/Main";
import Footer from "./organisms/Footer";
import NavDrawer from "./organisms/NavDrawer";
import SettingDrawer from "./organisms/SettingDrawer";
import Task from "./pages/Task";
import { selectLoading } from "./store/globalSlice";
import { useAppSelector } from "./store/hooks";
import IKDSchedule from "./pages/ocean-fishing/IKDSchedule";
import IKDRoute from "./pages/ocean-fishing/IKDRoute";

const Animal = lazy(() => import("./pages/animal/Animal"));
const About = lazy(() => import("./pages/About"));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // ...(open && {
  //   marginLeft: DRAWER_WIDTH,
  //   width: `calc(100% - ${DRAWER_WIDTH}px)`,
  //   transition: theme.transitions.create(['width', 'margin'], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // }),
}));

function App() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const loading = useAppSelector(selectLoading);

  const [navOpen, setNavOpen] = React.useState<boolean>(false);
  const showNavDrawer = React.useMemo<boolean>(
    () => (isMobile && navOpen) || !isMobile,
    [isMobile, navOpen]
  );
  const menuOptions = [
    { title: t("menu.animal"), icon: <CatchingPokemonIcon />, link: "animal" },
    {
      title: t("menu.oceanFishing"),
      icon: <DirectionsBoatIcon />,
      link: "oceanFishing",
    },
    // { title: t("menu.task"), icon: <FormatListBulletedIcon />, link: "task" },
  ];

  const [settingDrawerOpen, setSettingDrawerOpen] = React.useState(false);

  const toggleSettingsOpen = () => {
    setSettingDrawerOpen((prev) => !prev);
  };

  const handleSettingClose = () => {
    setSettingDrawerOpen(false);
  };

  return (
    <Container
      className="App"
      maxWidth={false}
      sx={{ display: "flex" }}
      disableGutters={true}
    >
      {loading && <Loader />}
      <AppBar position="fixed" elevation={1}>
        <Toolbar>
          {isMobile && (
            <IconButton
              edge="start"
              onClick={() => setNavOpen((prev) => !prev)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Avatar
            variant="square"
            src={`${LOCAL_STATIC_BASE}/logo128.png`}
            sx={{
              marginLeft: isMobile ? 0 : -1.5,
              marginRight: 2.5,
              // ...(navOpen && { display: 'none' }),
            }}
          />
          <Box sx={{ flexGrow: 0 }}>
            <Typography variant="h6" component="div">
              {t("top.appBar.title")}
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ flexGrow: 0 }}>
            <IconButton color="inherit" onClick={() => toggleSettingsOpen()}>
              <SettingsIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <NavDrawer open={showNavDrawer} options={menuOptions} />
      <SettingDrawer open={settingDrawerOpen} onClose={handleSettingClose} />
      <Main
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          maxHeight: "100vh",
          overflow: "hidden",
        }}
      >
        <Toolbar />
        <Routes>
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loader />}>
                <Animal />
              </React.Suspense>
            }
          ></Route>
          <Route
            path="animal"
            element={
              <React.Suspense fallback={<Loader />}>
                <Animal />
              </React.Suspense>
            }
          ></Route>
          <Route
            path="task"
            element={
              <React.Suspense fallback={<Loader />}>
                <Task />
              </React.Suspense>
            }
          ></Route>
          <Route path="oceanFishing">
            <Route
              path=""
              element={
                <React.Suspense fallback={<Loader />}>
                  {/*TODO replace with IKD*/}
                  <IKDSchedule />
                </React.Suspense>
              }
            />
            <Route
              path="route/:id"
              element={
                <React.Suspense fallback={<Loader />}>
                  <IKDRoute />
                </React.Suspense>
              }
            />
            <Route
              path="schedule"
              element={
                <React.Suspense fallback={<Loader />}>
                  <IKDSchedule />
                </React.Suspense>
              }
            />
          </Route>

          <Route
            path="about"
            element={
              <React.Suspense fallback={<Loader />}>
                <About />
              </React.Suspense>
            }
          ></Route>
        </Routes>
        <div style={{ flexGrow: 1 }}></div>
        <Footer />
      </Main>
    </Container>
  );
}

export default App;
