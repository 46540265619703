import {
  FormControl,
  FormLabel,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from '@mui/material';
import React, { ReactNode } from 'react';

export type OptionValueType = string | number;

export interface ButtonFilterOption {
  name: string;
  value: OptionValueType;
}

interface ButtonFilterProps {
  label: ReactNode;
  options: ButtonFilterOption[];
  exclusive?: boolean;
  atLeastOne?: boolean;
  value?: OptionValueType | OptionValueType[];
  onChange?: (values: OptionValueType | OptionValueType[]) => void;
}

const ButtonFilter: React.FC<ButtonFilterProps> = ({
  label,
  options,
  exclusive = false,
  atLeastOne = true,
  value,
  onChange,
}) => {
  const handleChange = React.useCallback(
    (values: OptionValueType[]) => {
      if (atLeastOne) {
        if (
          (exclusive && values != null) ||
          (!exclusive && values != null && values.length !== 0)
        ) {
          onChange && onChange(values);
        }
      }
    },
    [atLeastOne, exclusive, onChange]
  );
  const theme = useTheme();
  return (
    <>
      <FormControl sx={{my:1}} fullWidth>
        <FormLabel
          sx={{
            m: theme.spacing(1, 2, 2),
          }}
        >
          {label}
        </FormLabel>
        <ToggleButtonGroup
          color="primary"
          size="small"
          value={value}
          exclusive={exclusive}
          onChange={(e, v) => handleChange(v)}
          fullWidth
          sx={{
            p: theme.spacing(0, 2),
          }}
        >
          {options.map((option) => (
            <ToggleButton key={option.value} value={option.value} >
              {option.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </FormControl>
    </>
  );
};

export default ButtonFilter;
