import { PlaceName as PlaceNameData } from "@ffxiv-momola/data";

import { EName } from "../models/base";
import { EPlaceName } from "../models/EPlaceName";

type PlaceName = {
  id: number;
  en: string;
  ja: string;
  chs: string;
};

type PlaceNames = {
  placeNames: PlaceName[];
};

class PlaceNameRepository {
  readonly data: Map<number, EPlaceName>;

  constructor(placeNames: PlaceNames) {
    this.data = new Map();
    for (let placeName of placeNames.placeNames) {
      this.data.set(placeName.id, {
        id: placeName.id,
        name: new EName({
          chs: placeName.chs,
          en: placeName.en,
          ja: placeName.ja,
        }),
      } as EPlaceName);
    }
  }

  get(id: number) {
    return this.data.get(id);
  }
}

const PlaceNameRepo = new PlaceNameRepository(PlaceNameData);

export default PlaceNameRepo;
