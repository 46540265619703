import { EIcon, EItem, EName, Position } from "./base";
import { EWeather } from "./EWeather";

export class EMJIAnimal {
  id: number;
  version: number;
  name: EName;
  size: EMJIAnimalSize;
  icon: EIcon;
  itemPouched: EItem[];

  weather: EWeather[];
  startHour: number;
  endHour: number;
  positions: Position[];
  requireFly: boolean;
  isInCave: boolean;
  cavePositions: Position[];

  constructor(
    id: number,
    version: number,
    name: EName,
    size: EMJIAnimalSize,
    icon: EIcon,
    itemPouched: EItem[],
    weather: EWeather[],
    startHour: number,
    endHour: number,
    positions: Position[],
    requireFly: boolean,
    isInCave: boolean,
    cavePositions: Position[]
  ) {
    this.id = id;
    this.version = version;
    this.name = name;
    this.size = size;
    this.icon = icon;
    this.itemPouched = itemPouched;
    this.weather = weather;
    this.startHour = startHour;
    this.endHour = endHour;
    this.positions = positions;
    this.requireFly = requireFly;
    this.isInCave = isInCave;
    this.cavePositions = cavePositions;
  }

  weatherRestricted() {
    return this.weather.length > 0;
  }

  timeRestricted() {
    return this.startHour !== 0 || this.endHour !== 24;
  }
}

export type EMJIAnimalSize = "small" | "medium" | "large";
export const EMJIAnimalSizeOf = (size: number): EMJIAnimalSize =>
  ["small", "medium", "large"][size - 1] as EMJIAnimalSize;

export interface EMJIITEMPouch {
  item: EItem;
}
