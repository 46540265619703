import React from 'react';
import { Divider, Drawer, Stack } from '@mui/material';
import DrawerHeader from '../molecules/DrawerHeader';
import Box from '@mui/material/Box';
import LanguageIcon from '@mui/icons-material/Language';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Locale } from '../models/base';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  selectGameDataLocale,
  selectThemeMode,
  setGameDataLocale,
  setThemeMode,
  ThemeMode,
} from '../store/settingsSlice';
import ButtonFilter, { ButtonFilterOption } from '../molecules/ButtonFilter';
import BrightnessMediumIcon from '@mui/icons-material/BrightnessMedium';

interface SettingDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const DRAWER_WIDTH = 240;

const SettingDrawer: React.FC<SettingDrawerProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const gameDataLocales: Locale[] = ['chs', 'en', 'ja'];
  const gameDataLocaleOptions: ButtonFilterOption[] = gameDataLocales.map(
    (value) => ({
      name: t(`locale.options.${value}`),
      value: value,
    })
  );
  const gameDataLocale = useAppSelector(selectGameDataLocale);
  const handleGDLocaleChanged = (locale: Locale) => {
    dispatch(setGameDataLocale(locale));
  };

  const themeModes: ThemeMode[] = ['dark', 'light', 'auto'];
  const themeModeOptions: ButtonFilterOption[] = themeModes.map((value) => ({
    name: t(`theme.option.${value}`),
    value: value,
  }));
  const themeMode = useAppSelector(selectThemeMode);
  const handleThemeModeChange = (theme: ThemeMode) => {
    dispatch(setThemeMode(theme));
  };

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={onClose}
      PaperProps={{
        sx: {
          minWidth: `${DRAWER_WIDTH}px`
        },
      }}
    >
      <DrawerHeader title={t('setting.quickSetting')} onClose={onClose} />
      <Divider />
      <Stack spacing={1} divider={<Divider flexItem />}>
        <ButtonFilter
          label={
            <Box sx={{ display: 'flex' }}>
              <LanguageIcon sx={{ pr: 1 }} />
              <Typography>{t('locale.gameData')}</Typography>
            </Box>
          }
          options={gameDataLocaleOptions}
          value={gameDataLocale}
          onChange={(v) => handleGDLocaleChanged(v as Locale)}
          exclusive
        />
        <ButtonFilter
          label={
            <Box sx={{ display: 'flex' }}>
              <BrightnessMediumIcon sx={{ pr: 1 }} />
              <Typography>{t('theme.title')}</Typography>
            </Box>
          }
          options={themeModeOptions}
          value={themeMode}
          onChange={(v) => handleThemeModeChange(v as ThemeMode)}
          exclusive
        />
      </Stack>
    </Drawer>
  );
};

export default SettingDrawer;
