import { EorzeaTime } from '../models/eTime';
import { FISH_WINDOW_FORECAST_N } from '../data/env/constants';

export function nextNWindows(
  hourStart: number,
  hourEnd: number,
  previousWeatherSet: number[],
  weatherSet: number[],
  weatherRates: number[][],
  requiredWindowCnt: number =FISH_WINDOW_FORECAST_N
): number[][] {
  // const requiredWindowCnt = FISH_WINDOW_FORECAST_N;
  const now = new EorzeaTime(EorzeaTime.toEorzeaTime(new Date().getTime()));

  // add 1 to make sure nextInterval process can also get enough fish windows
  // add another 1 to make sure previous fish windows take continuous fish windows
  // (which should be merged) into account
  const n = requiredWindowCnt + 2;
  if (
    previousWeatherSet.length === 0 &&
    weatherSet.length === 0 &&
    hourStart === 0 &&
    hourEnd === 24
  ) {
    // console.warn('not time and weather restraint fish!')
    return [];
  }
  // combine fish windows if connected
  // also ensure return count = n
  const itemWindows: number[][] = [];
  let counter = 0;
  let time = now.toWeatherCheckPoint();
  const firstTime = time;
  let loopCounter = 0;
  while (counter < n && loopCounter < 10000) {
    loopCounter++;
    const periodItemWindows = computeItemWindowIfExist(
      weatherRates,
      time,
      hourStart,
      hourEnd,
      previousWeatherSet,
      weatherSet
    );
    for (const itemWindow of periodItemWindows) {
      if (itemWindows.length > 0) {
        const lastIndex = itemWindows.length - 1;

        const lastItem = itemWindows[lastIndex];
        if (lastItem[1] === itemWindow[0]) {
          itemWindows.splice(itemWindows.length - 1, 1, [
            lastItem[0],
            itemWindow[1],
          ]);
        } else {
          itemWindows.push(itemWindow);
          counter++;
        }
      } else {
        itemWindows.push(itemWindow);
        counter++;
      }
    }
    time = time.toNextWeatherInterval();
  }
  // try to find previous available fish window which can be combined with the current first one
  if (itemWindows.length > 0 && firstTime.toEarthTime() === itemWindows[0][0]) {
    loopCounter = 0;
    let firstEorzeaWeatherStart = firstTime;
    // console.log(fishId, weatherAt(territoryId, firstEorzeaWeatherStart))
    let firstItemWindow; //= itemWindows[0]
    let itemWindowsOfPeriod;
    do {
      firstEorzeaWeatherStart =
        firstEorzeaWeatherStart.toPreviousWeatherInterval();
      itemWindowsOfPeriod = computeItemWindowIfExist(
        weatherRates,
        firstEorzeaWeatherStart,
        hourStart,
        hourEnd,
        previousWeatherSet,
        weatherSet
      );
      loopCounter++;
      if (itemWindowsOfPeriod.length === 0) {
        firstItemWindow = undefined;
      } else if (itemWindowsOfPeriod.length === 1) {
        firstItemWindow = itemWindowsOfPeriod[0];
      } else {
        firstItemWindow = itemWindowsOfPeriod[1];
      }
      // console.log(
      //   'seek for fish',
      //   fishId,
      //   weatherAt(territoryId, firstEorzeaWeatherStart),
      //   firstItemWindow && new Date(firstItemWindow[0]),
      //   firstItemWindow && new Date(firstItemWindow[1]),
      //   new Date(firstEorzeaWeatherStart.toNextWeatherInterval().toEarthTime())
      // )
    } while (
      // check itemWindow contains the whole weather time range
      firstItemWindow &&
      firstEorzeaWeatherStart.toEarthTime() === firstItemWindow[0] &&
      firstEorzeaWeatherStart.toNextWeatherInterval().toEarthTime() ===
        firstItemWindow[1] &&
      loopCounter < 1000
    );
    // console.log('loop cnt for fish front seeker', loopCounter)
    if (
      firstItemWindow &&
      firstEorzeaWeatherStart.toNextWeatherInterval().toEarthTime() ===
        firstItemWindow[1]
    ) {
      itemWindows[0][0] = firstItemWindow[0];
    } else {
      itemWindows[0][0] = firstEorzeaWeatherStart
        .toNextWeatherInterval()
        .toEarthTime();
    }
  }
  // console.debug('loop count', loopCounter)
  let dummyWindows = [];
  const temp = Math.floor(now.toEarthTime());
  const baseTime = temp - (temp % 20000);
  for (let i = 1; i < n + 11; i++) {
    dummyWindows.push([baseTime + i * 20000, baseTime + i * 20000 + 10000]);
  }
  let windowsRet = itemWindows
    .slice(0, n - 1)
    .filter((it) => it[1] > now.toEarthTime());
  if (windowsRet.length > requiredWindowCnt) {
    windowsRet = windowsRet.slice(0, requiredWindowCnt);
  }
  if (windowsRet.length < requiredWindowCnt) {
    console.warn(
      'return window less than required',
      windowsRet.length,
      requiredWindowCnt
    );
  }
  return windowsRet;
}

function computeItemWindowIfExist(
  weatherRates: number[][],
  periodStart: EorzeaTime,
  hourStart: number,
  hourEnd: number,
  previousWeatherSet: number[],
  weatherSet: number[]
) {
  const prevPeriodStart = periodStart.toPreviousWeatherInterval();

  const periodStartHour = periodStart.getHours();
  const periodEndHour = periodStartHour + 8;

  const intersections = intersectionOf(
    [periodStartHour, periodEndHour],
    [hourStart, hourEnd]
  );
  if (intersections.length === 0) {
    return [];
  }
  if (
    weatherRates == null ||
    ((previousWeatherSet.length === 0 ||
      previousWeatherSet.indexOf(weatherAt(weatherRates, prevPeriodStart)) !==
        -1) &&
      (weatherSet.length === 0 ||
        weatherSet.indexOf(weatherAt(weatherRates, periodStart)) !== -1))
  ) {
    return intersections.map((range) =>
      range.map((hour) => periodStart.timeOfHours(hour).toEarthTime())
    );
  }
  return [];
}

function weatherAt(weatherRates: number[][], eorzeaTime: EorzeaTime) {
  const earthTime = eorzeaTime.toEarthTime();
  const seed = calculateForecastTarget(earthTime);
  const weatherId = weatherRates.find((it) => seed < it[1])?.[0];
  return weatherId ? weatherId : 0; // TODO check
}

function calculateForecastTarget(m: number) {
  // Based on Rougeadyn's SaintCoinach library.
  // const unixTime = parseInt(+m / 1000)
  const unixTime = (m - (m % 1000)) / 1000;
  // Get the Eorzea hour for weather start.
  const bell = unixTime / 175;
  // Magic needed for calculations:
  // 16:00 = 0, 00:00 = 8, 08:00 = 16 . . .
  const inc = (bell + 8 - (bell % 8)) % 24;
  // Take the Eorzea days since Unix Epoch.
  const totalDays = ((unixTime / 4200) << 32) >>> 0; // uint

  // Make the calculations.
  const calcBase = totalDays * 100 + inc;
  const step1 = ((calcBase << 11) ^ calcBase) >>> 0;
  const step2 = ((step1 >>> 8) ^ step1) >>> 0;

  return step2 % 100;
}

function intersectionOf(range1: number[], range2: number[]) {
  let targetRanges = [range2];
  if (range2[0] > range2[1]) {
    targetRanges = [
      [0, range2[1]],
      [range2[0], 24],
    ];
  }
  return targetRanges
    .map((it) => intersectionOfSimpleRange(range1, it))
    .filter((it) => it.length !== 0);
}

function intersectionOfSimpleRange(range1: number[], range2: number[]) {
  const range = [
    Math.max(range1[0], range2[0]),
    Math.min(range1[1], range2[1]),
  ];
  if (range[0] >= range[1]) return [];
  return range;
}
