import Box from "@mui/material/Box";

import { EFish } from "../models/EFish";
import { RIcon } from "../atomic/RIcon";
import { selectGameDataLocale } from "../store/settingsSlice";
import { useAppSelector } from "../store/hooks";
import { BaitIcon } from "./BaitIcon";
import { EOceanFishingFish } from "../models/EOceanFishing";
import EastIcon from "@mui/icons-material/East";
import { TugIcon } from "./TugIcon";
import React from "react";
import { EBait } from "../models/EBait";
import { HooksetIcon } from "./HooksetIcon";
import { Badge, Stack } from "@mui/material";
interface FishCatchPathProps {
  fish: EFish;
  count?: number;
}

export const FishCatchPath: React.FC<FishCatchPathProps> = ({
  fish,
  count,
}) => {
  const locale = useAppSelector(selectGameDataLocale);

  const bait = fish.bestCatchPath[0] as EBait;
  const fishes = [...fish.bestCatchPath.slice(1), fish] as EOceanFishingFish[];
  const hideCount = !count || count <= 1;

  let baitIcon = (
    <RIcon icon={bait.icon} label={bait.name.getLocaleName(locale)} />
  );
  if (fish instanceof EOceanFishingFish) {
    baitIcon = <BaitIcon bait={bait} uniqueType={fish.baitUniqueType} />;
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
        {baitIcon}
        {fishes.map((fish, idx) => {
          return (
            <React.Fragment key={fish.id}>
              <EastIcon />
              <Stack sx={{ alignItems: "center", mr: 0.2 }} spacing={0.4}>
                <TugIcon tug={fish.tug} />
                <HooksetIcon hookset={fish.hookset} />
              </Stack>
              <Badge
                invisible={hideCount || idx < fishes.length - 1}
                color="warning"
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                badgeContent={count}
                overlap="circular"
              >
                <RIcon
                  icon={fish.icon}
                  label={fish.name.getLocaleName(locale)}
                  dense
                />
              </Badge>
            </React.Fragment>
          );
        })}
      </Box>
    </>
  );
};
