import React from "react";
import { EBait } from "../models/EBait";
import { EOceanFishingBaitUniqueType } from "../models/EOceanFishing";
import { Badge } from "@mui/material";
import { RIcon } from "../atomic/RIcon";
import { useTranslation } from "react-i18next";

interface BaitIconProps {
  bait: EBait;
  uniqueType: EOceanFishingBaitUniqueType;
}
export const BaitIcon: React.FC<BaitIconProps> = ({ bait, uniqueType }) => {
  const { t } = useTranslation();

  const badgeProps: {
    color: "primary" | "success";
    invisible: boolean;
    hint: string;
  } = {
    color: "primary",
    invisible: true,
    hint: "",
  };

  switch (uniqueType) {
    case "unique":
      badgeProps.color = "success";
      badgeProps.invisible = false;
      badgeProps.hint = t("oceanFishing.table.fish.cell.uniqueBait");
      break;
    case "semi-unique":
      badgeProps.invisible = false;
      badgeProps.hint = t("oceanFishing.table.fish.cell.semiUniqueBait");
      break;
    case "not-unique":
    default:
      badgeProps.invisible = true;
  }

  return (
    <Badge
      color={badgeProps.color}
      variant="dot"
      invisible={badgeProps.invisible}
      overlap="circular"
      componentsProps={{ badge: { title: badgeProps.hint } }}
    >
      <RIcon icon={bait.icon} label={bait.name} size="small" />
    </Badge>
  );
};
