import { last } from "lodash";

import { EFishingSpot } from "./EFishingSpot";
import { EIcon, EItem, EName } from "./base";
import { EFish, EFishingHookset, ETug } from "./EFish";
import { EWeather } from "./EWeather";
import { EAchievement } from "./EAchievement";
import { EBait } from "./EBait";

export interface EOceanFishingBonus {
  id: number;
  objective: EName;
  requirement: EName;
  bonusRate: number;
  icon: EIcon;
  order: number;
  achievement: EAchievement | null;
}

interface EFishPredator<T extends EFish> {
  fish: T;
  count: number;
}

export type EOceanFishingBaitUniqueType =
  | "not-unique"
  | "unique"
  | "semi-unique";

interface EBaitWithBiteTIme {
  bait: EItem;
  biteTimeMin: number;
  biteTimeMax: number;
}

interface EFishTip {
  content: string;
}

export class EOceanFishingFish extends EFish {
  isBlueFish: boolean;
  isBigFish: boolean;
  isSpectralFish: boolean;
  ikdTimes: EOceanFishingTime[];
  notAvailableWeathers: EWeather[];
  ikdSpot: EOceanFishingSpot;
  biteTimeMin: number;
  biteTimeMax: number;
  isBaitUnique: boolean;
  doubleHook: number[];
  tug: ETug;
  hookset: EFishingHookset;
  points: number;
  bonus?: EOceanFishingBonus;
  bestCatchPath: Array<EOceanFishingFish | EBait>;
  predators: Array<EFishPredator<EOceanFishingFish>>;
  exampleBaits: Array<EBait | EOceanFishingFish>;
  allBaitsWithBiteTime: EBaitWithBiteTIme[];
  tips: EFishTip[];

  get directBiteTimeMin(): number {
    const baitId = last(this.bestCatchPath)!!.id;
    return this.allBaitsWithBiteTime.find((b) => b.bait.id === baitId)!!
      .biteTimeMin;
  }

  get directBiteTimeMax(): number {
    const baitId = last(this.bestCatchPath)!!.id;
    return this.allBaitsWithBiteTime.find((b) => b.bait.id === baitId)!!
      .biteTimeMax;
  }

  get baitUniqueType(): EOceanFishingBaitUniqueType {
    if (!this.isBaitUnique) {
      return "not-unique";
    } else if (this.exampleBaits.length === 1) {
      return "unique";
    } else {
      return "semi-unique";
    }
  }

  get tripleHook(): number[] {
    return this.doubleHook.map((doubleHookCnt) => {
      switch (doubleHookCnt) {
        case 2:
          return 3;
        case 3:
          return 5;
        case 4:
          return 7;
        default:
          return doubleHookCnt;
      }
    });
  }

  constructor(
    fish: EFish,
    ikdSpot: EOceanFishingSpot,
    isBigFish: boolean,
    isSpectralFish: boolean,
    isBlueFish: boolean,
    ikdTimes: EOceanFishingTime[],
    notAvailableWeathers: EWeather[],
    biteTimeMin: number,
    biteTimeMax: number,
    isBaitUnique: boolean,
    doubleHook: number[],
    tug: ETug,
    hookset: EFishingHookset,
    points: number,
    tips: EFishTip[] = [],
    ikdContentBonus?: EOceanFishingBonus,
    baits: Array<EBait | EOceanFishingFish> = [],
    predators: Array<EFishPredator<EOceanFishingFish>> = [],
    allBaitsWithBiteTime: EBaitWithBiteTIme[] = []
  ) {
    super(fish.id, fish.name, fish.icon, fish.fishParameter, fish.spot);
    this.bestCatchPath = [];
    this.isBigFish = isBigFish;
    this.isSpectralFish = isSpectralFish;
    this.isBlueFish = isBlueFish;
    this.ikdTimes = ikdTimes;
    this.notAvailableWeathers = notAvailableWeathers;
    this.ikdSpot = ikdSpot;
    this.biteTimeMin = biteTimeMin;
    this.biteTimeMax = biteTimeMax;
    this.isBaitUnique = isBaitUnique;
    this.doubleHook = doubleHook;
    this.tug = tug;
    this.hookset = hookset;
    this.points = points;
    this.bonus = ikdContentBonus;
    this.exampleBaits = baits;
    this.predators = predators;
    this.allBaitsWithBiteTime = allBaitsWithBiteTime;
    this.tips = tips;
  }
}
export interface EOceanFishingSpot {
  id: number;
  name: EName; // spot general name
  mainSpot: EOceanFishingMainSpot; // main spot, normal spot
  subSpot: EOceanFishingSubSpot; // sub spot, spectral current spot
}

export interface EOceanFishingSpotPointTip {
  fishes: EOceanFishingFish[];
  content: string;
}

export class EOceanFishingMainSpot {
  type: EOceanFishingSpotType = "normal";
  spot: EFishingSpot;
  fishes: EOceanFishingFish[];
  weathers: EWeather[];
  pointTip: EOceanFishingSpotPointTip;
  get id() {
    return this.spot.id;
  }
  get name() {
    return this.spot.name;
  }

  constructor(
    spot: EFishingSpot,
    weathers: EWeather[],
    fishes: EOceanFishingFish[] = [],
    pointTip: EOceanFishingSpotPointTip = { fishes: [], content: "" }
  ) {
    this.spot = spot;
    this.fishes = fishes;
    this.weathers = weathers;
    this.pointTip = pointTip;
  }
}

export class EOceanFishingSubSpot {
  type: EOceanFishingSpotType = "spectral";
  spot: EFishingSpot;
  fishes: EOceanFishingFish[];
  pointTips: Map<number, EOceanFishingSpotPointTip>;
  get id() {
    return this.spot.id;
  }
  get name() {
    return this.spot.name;
  }

  constructor(
    spot: EFishingSpot,
    fishes: EOceanFishingFish[] = [],
    pointTips: Map<number, EOceanFishingSpotPointTip> = new Map()
  ) {
    this.spot = spot;
    this.fishes = fishes;
    this.pointTips = pointTips;
  }
}

export interface EOceanFishingTime {
  id: number;
  name: EName;
  icon: any;
}

interface EOceanFishingAchievementTip {
  fishes: EOceanFishingFish[];
  content: string;
}

export type EOceanFishingAchievementTips = {
  [spotId: number]: EOceanFishingAchievementTip;
};

export interface EOceanFishingAchievement {
  achievement: EAchievement;
  tips: EOceanFishingAchievementTips;
}

export interface EOceanFishingRoute {
  id: number;
  type: number;
  spots: EOceanFishingSpot[];
  times: EOceanFishingTime[];
  territoryType: number;
  name: EName;
  time: EOceanFishingTime;
  availableBlueFishes: EOceanFishingFish[];
  achievements: EOceanFishingAchievement[];
  isRecommended: boolean;
}

export interface EOceanFishingRouteGroup {
  id: string;
  name: EName;
  routes: EOceanFishingRoute[];
  routeTypes: number[];
}

export type EOceanFishingSpotType = "normal" | "spectral";
