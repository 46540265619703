import MuiBox from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const Main = styled(MuiBox)(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: theme.spacing(0),
  [theme.breakpoints.up('sm')]: {
    paddingLeft: `calc(${theme.spacing(7)} + 1px)`,
  },
  height: '100%',
}));

export default Main;
