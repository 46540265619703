import React from "react";
import { Box, ListSubheader, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectRouteGroup, setRouteGroup } from "../../store/oceanFishingSlice";

import { IKDRouteGroupInput } from "./IKDRouteGroupInput";

import { IKDScheduleCountInput } from "./IKDScheduleCountInput";
import { IKDScheduleTargetInput } from "./IKDScheduleTargetInput";

export const IKDScheduleFilters: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const routeGroup = useAppSelector(selectRouteGroup);
  const dispatch = useAppDispatch();

  return (
    <>
      <ListSubheader sx={{ zIndex: theme.zIndex.appBar }}>
        {t("oceanFishing.filters.title")}
      </ListSubheader>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          display: "grid",
          gridTemplateColumns: { sm: "1fr 1fr 1fr" },
          gap: 2,
          px: 2,
          py: 2,
        }}
      >
        <IKDRouteGroupInput
          value={routeGroup}
          onChange={(v) => dispatch(setRouteGroup(v))}
        />
        <IKDScheduleCountInput />
        <IKDScheduleTargetInput />
      </Box>
    </>
  );
};
