import { useTranslation } from "react-i18next";

import IKDRepo from "../data/ikd";
import { useAppSelector } from "../store/hooks";
import { selectGameDataLocale } from "../store/settingsSlice";

export const useIKDRouteGroupOptions = () => {
  const { t } = useTranslation();
  const locale = useAppSelector(selectGameDataLocale);
  const routeGroups = IKDRepo.allRouteGroups;

  return [{ id: "all", name: t("oceanFishing.filters.routeGroup.all") }].concat(
    routeGroups.map((it) => ({
      id: it.id,
      name: it.name.getLocaleName(locale),
    }))
  );
};
