import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import IKDRepo from "../data/ikd";

// 为 slice state 定义一个类型
interface OceanFishingState {
  filterRouteGroup: string;
  filterRouteCount: number;
  filterRouteTargets: string[];
}

// 使用该类型定义初始 state
const initialState: OceanFishingState = {
  filterRouteGroup: "all",
  filterRouteCount: 12,
  filterRouteTargets: [],
};

export const oceanFishingSlice = createSlice({
  name: "oceanFishing",
  // `createSlice` 将从 `initialState` 参数推断 state 类型
  initialState,
  reducers: {
    // 使用 PayloadAction 类型声明 `action.payload` 的内容
    setRouteGroup: (state, action: PayloadAction<string>) => {
      state.filterRouteGroup = action.payload;
    },
    setRouteCount: (state, action: PayloadAction<number>) => {
      state.filterRouteCount = action.payload;
    },
    setFilterRouteTargets: (state, action: PayloadAction<string[]>) => {
      state.filterRouteTargets = action.payload;
    },
  },
});

export const { setRouteGroup, setRouteCount, setFilterRouteTargets } =
  oceanFishingSlice.actions;
// 选择器等其他代码可以使用导入的 `RootState` 类型
export const selectRouteGroup = (state: RootState) => {
  const group =
    state.oceanFishing.filterRouteGroup ?? initialState.filterRouteGroup;
  return group === "all" ? group : IKDRepo.getRouteGroup(group)?.id ?? "all";
};
export const selectRouteCount = (state: RootState) =>
  state.oceanFishing.filterRouteCount ?? initialState.filterRouteCount;
export const selectFilterRouteTarget = (state: RootState) =>
  state.oceanFishing.filterRouteTargets ?? initialState.filterRouteTargets;
export default oceanFishingSlice.reducer;
