import { Item as ItemData, ItemName as ItemNameData } from "@ffxiv-momola/data";

import { EIcon, EItem, EName } from "../models/base";
import { find } from "lodash";
import { EBait } from "../models/EBait";

type ItemName = {
  id: number;
  chs?: string;
  en?: string;
  ja?: string;
};

type Item = {
  id: number;
  icon: number;
  type: string;
};

class ItemRepository {
  readonly data: Map<number, EItem>;

  constructor(items: Item[], itemNames: ItemName[]) {
    this.data = new Map();
    for (let item of items) {
      const { chs, en, ja } = find(itemNames, { id: item.id })!!;
      this.data.set(item.id, {
        id: item.id,
        name: new EName({ chs: chs, en, ja }),
        icon: new EIcon(item.icon),
        type: item.type,
      } as EItem);
    }
  }

  get(id: number) {
    return this.data.get(id)!!;
  }

  getBait(id: number) {
    const bait: EBait = this.data.get(id)!!;

    if (bait.type !== "bait") {
      throw new Error(`item ${id} is not bait`);
    }
    return bait;
  }
}

const ItemRepo = new ItemRepository(ItemData, ItemNameData);

export default ItemRepo;
