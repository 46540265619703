import { EOceanFishingFish } from "../../models/EOceanFishing";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";
import { selectGameDataLocale } from "../../store/settingsSlice";
import { Box, Card, Divider, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { RIcon } from "../../atomic/RIcon";
import { FishCatchPath } from "../../molecules/FishCatchPath";
import { biteTimeDisplayOf } from "../../utils/oceanFishing";
import { EWeather } from "../../models/EWeather";

interface WeatherNotAvailableProps {
  weathers: EWeather[];
}

const WeatherNotAvailable: React.FC<WeatherNotAvailableProps> = ({
  weathers,
}) => {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        color: "error.main",
        borderRight: "1px solid",
        px: 0.5,
      }}
    >
      <Typography>{t("general.not")}</Typography>
      <Box sx={{ display: "flex" }}>
        {weathers.map((weather) => {
          return (
            <RIcon
              key={weather.id}
              icon={weather.icon}
              label={weather.name}
              type="weather"
              size="xSmall"
              dense
            />
          );
        })}
      </Box>
    </Stack>
  );
};

interface FishCardProps {
  fish: EOceanFishingFish;
  variant: "point" | "fish";
}

export const FishCard: React.FC<FishCardProps> = ({ fish, variant }) => {
  const { t } = useTranslation();
  const locale = useAppSelector(selectGameDataLocale);

  const fishCardContentOf = (fish: EOceanFishingFish, count?: number) => {
    const showPoints = !count && variant === "point";
    return (
      <Box key={fish.id} sx={{ position: "relative" }}>
        {fish.notAvailableWeathers.length > 0 && (
          <WeatherNotAvailable weathers={fish.notAvailableWeathers} />
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FishCatchPath fish={fish} count={count} />
          <Stack sx={{ height: "100%", justifyContent: "center" }}>
            <Typography variant="subtitle2">
              {fish.name.getLocaleName(locale)}
            </Typography>
            <Typography variant="subtitle2">
              {biteTimeDisplayOf(
                fish.directBiteTimeMin,
                fish.directBiteTimeMax
              )}
            </Typography>
          </Stack>
        </Box>
        {showPoints && (
          <Stack
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderLeft: "1px solid",
              borderColor: "divider",
              px: 0.5,
            }}
          >
            <Typography>{fish.points}</Typography>
            <Typography>
              x {fish.doubleHook}/{fish.tripleHook}
            </Typography>
          </Stack>
        )}
      </Box>
    );
  };

  return (
    <Card
      square
      variant="outlined"
      sx={{
        borderRightStyle: "hidden",
        borderLeftStyle: "hidden",
      }}
    >
      <Stack divider={<Divider />}>
        {fishCardContentOf(fish)}
        {fish.predators.length > 0 && (
          <Stack
            sx={{
              backgroundColor: (theme) => theme.palette.background.default,
            }}
            divider={<Divider />}
          >
            <Typography sx={{ width: "100%", textAlign: "center" }}>
              {t("game.predators")}
            </Typography>
            {fish.predators.map((predator) => {
              return fishCardContentOf(predator.fish, predator.count);
            })}
          </Stack>
        )}
      </Stack>
    </Card>
  );
};
