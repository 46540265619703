import React from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "@mui/material";

interface MarkdownProps {
  children: string;
}

export const Markdown: React.FC<MarkdownProps> = ({ children }) => {
  return (
    <ReactMarkdown
      components={{
        a({ href, children }) {
          return (
            <Link
              href={href}
              variant="subtitle2"
              target="_blank"
              rel="noreferrer"
            >
              {children}
            </Link>
          );
        },
      }}
    >
      {children}
    </ReactMarkdown>
  );
};
