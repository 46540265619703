import React from "react";
import { useTranslation } from "react-i18next";

import { Alert, Link, Typography } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

import { IkdTip } from "../../data/raw/ikdTip";

export const IKDTipNotification: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Alert variant="outlined" severity={"info"} sx={{ mt: 1 }}>
      <Typography variant="body2">
        {t("oceanFishing.route.tip.reference")}
        <ul>
          {IkdTip.references.map((ref) => {
            return (
              <li>
                <Link
                  key={ref.title}
                  href={ref.link}
                  variant="subtitle2"
                  target="_blank"
                  rel="noreferrer"
                >
                  {ref.title} - {ref.author}
                  <LaunchIcon
                    sx={{ fontSize: "inherit", verticalAlign: "middle" }}
                  />
                </Link>
              </li>
            );
          })}
        </ul>
      </Typography>
    </Alert>
  );
};
