import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { Locale } from '../models/base';

export type ThemeMode = 'dark' | 'light' | 'auto';

// 为 slice state 定义一个类型
interface SettingsState {
  locale: Locale;
  gameDataLocale: Locale;
  themeMode: ThemeMode;
}

// 使用该类型定义初始 state
const initialState: SettingsState = {
  locale: 'chs',
  gameDataLocale: 'chs',
  themeMode: 'dark',
};

export const settingsSlice = createSlice({
  name: 'settings',
  // `createSlice` 将从 `initialState` 参数推断 state 类型
  initialState,
  reducers: {
    // 使用 PayloadAction 类型声明 `action.payload` 的内容
    setLocale: (state, action: PayloadAction<Locale>) => {
      state.locale = action.payload;
    },
    setGameDataLocale: (state, action: PayloadAction<Locale>) => {
      state.gameDataLocale = action.payload;
    },
    setThemeMode: (state, action: PayloadAction<ThemeMode>) => {
      state.themeMode = action.payload;
    },
  },
});

export const { setLocale, setGameDataLocale, setThemeMode } = settingsSlice.actions;
// 选择器等其他代码可以使用导入的 `RootState` 类型
export const selectLocale = (state: RootState) =>
  state.settings.locale ?? initialState.locale;
export const selectGameDataLocale = (state: RootState) =>
  state.settings.gameDataLocale ?? initialState.gameDataLocale;

export const selectThemeMode = (state: RootState) =>
  state.settings.themeMode ?? initialState.themeMode;

export default settingsSlice.reducer;
