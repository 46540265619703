import { Achievement as AchievementData } from "@ffxiv-momola/data";

import { EIcon, EName } from "../models/base";
import { EAchievement } from "../models/EAchievement";

type Achievement = {
  id: number;
  name: {
    en: string;
    ja: string;
    chs?: string;
  };
  description: {
    en: string;
    ja: string;
    chs?: string;
  };
  iconId: number;
};

class AchievementRepository {
  private readonly data: Map<number, EAchievement>;

  get allAchievements() {
    return Array.from(this.data.values());
  }

  constructor(achievements: Achievement[]) {
    this.data = new Map();
    for (let a of achievements) {
      this.data.set(a.id, {
        id: a.id,
        name: new EName({
          chs: a.name.chs,
          en: a.name.en,
          ja: a.name.ja,
        }),
        description: new EName({
          chs: a.description.chs,
          en: a.description.en,
          ja: a.description.ja,
        }),
        icon: new EIcon(a.iconId),
      } as EAchievement);
    }
  }

  get(id: number) {
    return this.data.get(id)!!;
  }
}

const AchievementRepo = new AchievementRepository(AchievementData);

export default AchievementRepo;
