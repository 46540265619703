import React from "react";
import { EFishingHookset } from "../models/EFish";
import { RIcon } from "../atomic/RIcon";
import { EIcon, ICON_ID } from "../models/base";
import { useTranslation } from "react-i18next";

interface HooksetIconProps {
  hookset: EFishingHookset;
}

export const HooksetIcon: React.FC<HooksetIconProps> = ({ hookset }) => {
  const { t } = useTranslation();
  return (
    <RIcon
      icon={EIcon.of(
        hookset === "precision"
          ? ICON_ID.ACTION_PRECISION_HOOKSET
          : ICON_ID.ACTION_POWERFUL_HOOKSET
      )}
      label={t("oceanFishing.table.fish.cell.fisherIntuition")}
      size="xSmall"
      type="action"
      dense
    />
  );
};
