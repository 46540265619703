import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Alert, Box, Button, Container, Tab, Tabs } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import IKDRepo from "../../data/ikd";
import { Markdown } from "../../atomic/Markdown";

import { IKDRouteFish } from "./IKDRouteFish";
import { IKDRoutePoint } from "./IKDRoutePoint";
import { IKDRouteBlueFish } from "./IKDRouteBlueFish";
import { IKDRouteAchievement } from "./IKDRouteAchievement";

const notificationMarkdown = `
- 海钓基本功能已施工完成
- 数据来源由人工记录，并进行了近似处理，可能存在一定的误差。[报修页面](https://nga.178.com/read.php?tid=34760969)
- 显示的鱼饵只是推荐，可行的钓饵在大多数情况下**不止一个**
  - 鼠标悬停在咬钩时间区域可以查看目前记录到的所有鱼饵的咬钩时间
  - 带有绿点的鱼饵为该鱼的唯一鱼饵
`;

const IKDRoute: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const route = IKDRepo.getRoute(+(id ?? 0));

  const tipTypes = ["allFish", "point", "blueFish", "achievement"].filter(
    (it) =>
      (it !== "blueFish" || route.availableBlueFishes.length > 0) &&
      (it !== "achievement" || route.achievements.length > 0)
  );
  const [tipType, setTipType] = React.useState(tipTypes[0]);

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        px: 1,
      }}
    >
      <Alert sx={{ mx: -1, borderRadius: 0 }} severity="info">
        {t("general.wip")}
        <Markdown>{notificationMarkdown}</Markdown>
      </Alert>
      <Box sx={{ mt: 1 }}>
        <Button
          variant="text"
          color="inherit"
          onClick={() => navigate("/oceanFishing")}
          startIcon={<ArrowBackIcon />}
        >
          {t("general.back")}
        </Button>
      </Box>
      <Tabs
        value={tipType}
        onChange={(_, value) => setTipType(value)}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
      >
        {tipTypes.map((tipType) => {
          return (
            <Tab
              key={tipType}
              label={t(`oceanFishing.route.type.${tipType}`)}
              value={tipType}
            />
          );
        })}
      </Tabs>
      {tipType === "allFish" && <IKDRouteFish route={route} />}
      {tipType === "point" && <IKDRoutePoint route={route} />}
      {tipType === "blueFish" && <IKDRouteBlueFish route={route} />}
      {tipType === "achievement" && <IKDRouteAchievement route={route} />}
    </Container>
  );
};

export default IKDRoute;
