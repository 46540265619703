import { Store } from "redux";
import animalWorker from "./animalWorker";

export type CountDownWorker = {
  calcCountDown: (store: Store) => void;
};

const workers = {
  animal: animalWorker
};
export default workers;
