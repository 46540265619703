import { EMPTY_NAME, EName } from "./base";
import { EFish } from "./EFish";

export interface EFishingSpot {
  id: number;
  name: EName;
  mainName?: EName;
  subName?: EName;
  fishes: EFish[];
}

export const EMPTY_FISHING_SPOT = {
  id: 0,
  name: EMPTY_NAME,
  fishes: [],
};
