import React from "react";
import { useTranslation } from "react-i18next";

import { Card, CardHeader, Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

import { EOceanFishingRoute } from "../../models/EOceanFishing";
import { RIcon } from "../../atomic/RIcon";
import { useAppSelector } from "../../store/hooks";
import { selectGameDataLocale } from "../../store/settingsSlice";

import { SpotCard } from "./SpotCard";
import { convertTip } from "./spotHelper";
import { IKDTipNotification } from "./IKDTipNotification";

interface IKDRouteBlueFishProps {
  route: EOceanFishingRoute;
}

export const IKDRouteBlueFish: React.FC<IKDRouteBlueFishProps> = ({
  route,
}) => {
  const locale = useAppSelector(selectGameDataLocale);
  const { t } = useTranslation();

  return (
    <>
      <IKDTipNotification />
      <Grid2 container spacing={2} sx={{ mt: 1 }}>
        {route.spots.map((spot, spotIdx) => {
          const time = route.times[spotIdx];
          const blueFish = spot.subSpot.fishes.find((f) => f.isBlueFish)!!;
          const isBlueFishAvailable = blueFish.ikdTimes
            .map((t) => t.id)
            .includes(route.times[spotIdx].id);
          const blueFishName = blueFish.name.getLocaleName(locale);
          const blueFishTip = (
            <>{blueFish.tips.map((tip) => convertTip(tip.content))}</>
          );
          const spectralFish = spot.mainSpot.fishes.filter(
            (it) => it.isSpectralFish
          );
          return (
            <Grid2 xs={12} sm={4} key={spot.id}>
              {isBlueFishAvailable ? (
                <Stack spacing={2}>
                  <SpotCard
                    spot={spot.mainSpot}
                    variant="fish"
                    fishes={spectralFish}
                  />
                  <SpotCard
                    spot={spot.subSpot}
                    time={time}
                    variant="fish"
                    fishes={blueFish ? [blueFish] : []}
                    tip={blueFishTip}
                  />
                </Stack>
              ) : (
                <Card>
                  <CardHeader
                    avatar={<RIcon label={blueFishName} icon={blueFish.icon} />}
                    title={
                      <Typography variant="body1">
                        {t("oceanFishing.route.blueFish.blueFishNotAvailable", {
                          blueFish: blueFishName,
                        })}
                      </Typography>
                    }
                  ></CardHeader>
                </Card>
              )}
            </Grid2>
          );
        })}
      </Grid2>
    </>
  );
};
