import React from "react";
import { findIndex } from "lodash";

import { Box, Icon, Paper } from "@mui/material";
import { selectGameDataLocale } from "../../store/settingsSlice";
import { useAppSelector } from "../../store/hooks";
import {
  EOceanFishingRoute,
  EOceanFishingSpotType,
} from "../../models/EOceanFishing";

import { IKDFishTable } from "./IKDFishTable";
import { IKDRouteFilters } from "./IKDRouteFilters";

interface IKDRouteFishProps {
  route: EOceanFishingRoute;
}

export const IKDRouteFish: React.FC<IKDRouteFishProps> = ({ route }) => {
  const locale = useAppSelector(selectGameDataLocale);
  const [spotId, setSpotId] = React.useState(route.spots[0].id);
  const spotIdx = findIndex(route.spots, { id: spotId });
  const spot = route.spots[spotIdx];
  const spotIkdTime = route.times[spotIdx];
  const [spotTypes, setSpotTypes] = React.useState<EOceanFishingSpotType[]>([
    "normal",
    "spectral",
  ]);
  let mainSpot = spotTypes.includes("normal") ? spot?.mainSpot : null;
  let subSpot = spotTypes.includes("spectral") ? spot?.subSpot : null;

  return (
    <>
      <Paper sx={{ mt: 1 }}>
        <IKDRouteFilters
          route={route}
          spotId={spotId}
          onSpotIdChange={setSpotId}
          spotTypes={spotTypes}
          onSpotTypesChange={setSpotTypes}
        />
      </Paper>
      <Paper sx={{ mt: 1 }}>
        {mainSpot ? (
          <IKDFishTable
            name={mainSpot.name.getLocaleName(locale)}
            fishes={mainSpot.fishes}
            type={mainSpot.type}
          />
        ) : null}
        {subSpot ? (
          <IKDFishTable
            name={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <div>{subSpot.name.getLocaleName(locale)}</div>
                <Icon component={spotIkdTime.icon} sx={{ ml: 0.5 }}></Icon>
              </Box>
            }
            fishes={subSpot.fishes}
            type={subSpot.type}
            time={spotIkdTime}
          />
        ) : null}
      </Paper>
    </>
  );
};
