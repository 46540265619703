import React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';

interface DrawerHeadProps {
  title?: string;
  onClose?: () => void;
}

const DrawerHeader: React.FC<DrawerHeadProps> = ({ title, onClose }) => {
  return (
    <Toolbar disableGutters sx={{
      display:"flex",
      justifyContent:"space-between",
      py: 1,
    }}>
      {title && <Typography variant="h6"
      sx={{pl: 2}}>{title}</Typography>}
      {onClose && (
        <IconButton
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Toolbar>
  );
};

// const DrawerHeader = styled(Header)(({ theme }) => ({
//   backgroundColor: 'red',
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
//   justifyContent: 'flex-end',
// }));

export default DrawerHeader;
