import React from "react";
import { useTranslation } from "react-i18next";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { useIKDRouteGroupOptions } from "../../hooks/useIKDRouteGroupOptions";

interface RouteGroupInputProps {
  value: string;
  onChange: (it: string) => void;
}

export const IKDRouteGroupInput: React.FC<RouteGroupInputProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const options = useIKDRouteGroupOptions();

  return (
    <FormControl>
      <InputLabel id="route-group-select-label">
        {t("oceanFishing.filters.routeGroup.title")}
      </InputLabel>
      <Select
        labelId="route-group-select-label"
        id="route-group-select"
        value={value}
        label={t("oceanFishing.filters.routineGroup")}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((it) => (
          <MenuItem value={it.id} key={it.id}>
            {it.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
