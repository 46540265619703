import { EIcon, EItem, EName } from "./base";
import { EFishingSpot, EMPTY_FISHING_SPOT } from "./EFishingSpot";
import { EBait } from "./EBait";

export type EFishingHookset = "precision" | "powerful";

interface EFishParameter {
  id: number;
  itemId: number;
  isHidden: boolean;
  isInLog: boolean;
  oceanStars: number;
}

const EmptyFishParameter: EFishParameter = {
  id: 0,
  itemId: 0,
  isHidden: false,
  isInLog: true,
  oceanStars: 0,
};

export type ETugType = "light" | "medium" | "heavy" | "unknown";
type ETugMark = "!" | "!!" | "!!!" | "?";
type ETugColor = "success" | "error" | "warning" | "default";

export class ETug {
  type: ETugType;
  mark: ETugMark;
  color: ETugColor;

  constructor(type: ETugType, mark: ETugMark, color: ETugColor) {
    this.type = type;
    this.mark = mark;
    this.color = color;
  }

  static Light = new ETug("light", "!", "success");
  static Medium = new ETug("medium", "!!", "error");
  static Heavy = new ETug("heavy", "!!!", "warning");
  static Empty = new ETug("unknown", "?", "default");

  static fromTugType(tug: ETugType) {
    switch (tug) {
      case "light":
        return ETug.Light;
      case "medium":
        return ETug.Medium;
      case "heavy":
        return ETug.Heavy;
      case "unknown":
      default:
        return ETug.Empty;
    }
  }
}

export class EFish extends EItem {
  fishParameter: EFishParameter;
  spot: EFishingSpot;
  bestCatchPath: Array<EFish | EBait>;
  tug: ETug;

  static Empty = new EFish(0, EName.Empty, EIcon.Empty, EmptyFishParameter);

  constructor(
    id: number,
    name: EName,
    icon: EIcon,
    fishParameter: EFishParameter,
    spot: EFishingSpot = EMPTY_FISHING_SPOT,
    tug: ETug = ETug.Empty
  ) {
    super(id, name, icon, "fish");
    this.fishParameter = fishParameter;
    this.spot = spot;
    this.tug = tug;
    this.bestCatchPath = [];
  }

  static fromItem(
    item: EItem,
    fishParameter: EFishParameter,
    spot?: EFishingSpot
  ) {
    return new EFish(item.id, item.name, item.icon, fishParameter, spot);
  }
}
