import { find } from "lodash";

import {
  MJIAnimals as MJIAnimalsData,
  MJIAnimalsInfo as MJIAnimalsInfoData,
} from "@ffxiv-momola/data";

import BNpcNameRepo from "./bNpcName";

import { EIcon } from "../models/base";
import { EMJIAnimal, EMJIAnimalSizeOf } from "../models/EMJI";

import ItemRepo from "./item";
import WeatherRepo from "./weather";

interface MJIAnimal {
  id: number;
  bNpcName: number;
  size: number;
  reward: number[];
  icon: number;
}

interface MJIAnimalsInfo {
  id: number;
  version: number;
  startHour: number;
  endHour: number;
  prevWeatherIds: number[];
  weatherIds: number[];
  positions: number[][];
  requireFly: boolean;
  isInCave: boolean;
  cavePositions: number[][];
}

class EMJIAnimalRepository {
  readonly data: Map<number, EMJIAnimal>;

  constructor(animals: MJIAnimal[], animalsInfos: MJIAnimalsInfo[]) {
    this.data = new Map();

    for (const animal of animals) {
      const info = find(animalsInfos, { id: animal.id })!!;

      this.data.set(
        animal.id,
        new EMJIAnimal(
          animal.id,
          info.version,
          BNpcNameRepo.get(animal.bNpcName)!!.name,
          EMJIAnimalSizeOf(animal.size),
          new EIcon(animal.icon),
          animal.reward.map((id) => ItemRepo.get(id)!!),
          info.weatherIds.map((w) => WeatherRepo.get(w)!!),
          info.startHour,
          info.endHour,
          info.positions.map((p) => ({ x: p[0], y: p[1] })),
          info.requireFly,
          info.isInCave,
          info.cavePositions.map((p) => ({ x: p[0], y: p[1] }))
        )
      );
    }
  }

  get(id: number) {
    return this.data.get(id)!!;
  }

  getAll() {
    return Array.from(this.data.values());
  }
}

const EMJIAnimalRepo = new EMJIAnimalRepository(
  MJIAnimalsData,
  MJIAnimalsInfoData
);

export default EMJIAnimalRepo;
