import React, { ReactNode } from "react";

import {
  Card,
  CardContent,
  CardHeader,
  Icon,
  Typography,
  useTheme,
} from "@mui/material";

import {
  EOceanFishingFish,
  EOceanFishingMainSpot,
  EOceanFishingSubSpot,
  EOceanFishingTime,
} from "../../models/EOceanFishing";
import { useAppSelector } from "../../store/hooks";
import { selectGameDataLocale } from "../../store/settingsSlice";

import { FishCard } from "./FishCard";

interface SpotCardProps {
  spot: EOceanFishingMainSpot | EOceanFishingSubSpot;
  time?: EOceanFishingTime;
  variant: "point" | "fish";
  tip?: ReactNode;
  fishes: EOceanFishingFish[];
}

export const SpotCard: React.FC<SpotCardProps> = ({
  time,
  spot,
  variant,
  tip,
  fishes,
}) => {
  const locale = useAppSelector(selectGameDataLocale);
  const isMain = spot instanceof EOceanFishingMainSpot;
  const theme = useTheme();
  return (
    <Card
      sx={{ backgroundColor: isMain ? null : theme.custom.row.completed.main }}
    >
      <CardHeader
        title={
          <Typography sx={{ display: "flex", alignItems: "center" }}>
            {spot.name.getLocaleName(locale)}
            {time && <Icon component={time.icon} sx={{ ml: 0.5 }}></Icon>}
          </Typography>
        }
      />

      {fishes.map((fish) => {
        return <FishCard key={fish.id} fish={fish} variant={variant} />;
      })}

      <CardContent>{tip}</CardContent>
    </Card>
  );
};
