import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Icon, ListSubheader } from "@mui/material";
import Typography from "@mui/material/Typography";

import { RToggleButtons } from "../../atomic/RToggleButtons";
import {
  EOceanFishingRoute,
  EOceanFishingSpotType,
} from "../../models/EOceanFishing";
import { useAppSelector } from "../../store/hooks";
import { selectGameDataLocale } from "../../store/settingsSlice";

interface IKDRouteFiltersProps {
  route: EOceanFishingRoute;
  spotId: number;
  onSpotIdChange: (it: number) => void;
  spotTypes: EOceanFishingSpotType[];
  onSpotTypesChange: (it: EOceanFishingSpotType[]) => void;
}

export const IKDRouteFilters: React.FC<IKDRouteFiltersProps> = ({
  route,
  spotId,
  onSpotIdChange,
  spotTypes,
  onSpotTypesChange,
}) => {
  const { t } = useTranslation();
  const locale = useAppSelector(selectGameDataLocale);

  const spotTypeOptions = [
    { id: "normal", name: t("oceanFishing.filters.spotType.normal") },
    { id: "spectral", name: t("oceanFishing.filters.spotType.spectral") },
  ];

  const spotOptions = route.spots.map((spot, idx) => ({
    id: spot.id,
    name: (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2">
          {spot.name.getLocaleName(locale)}
        </Typography>
        <Icon component={route.times[idx].icon} sx={{ ml: 0.5 }}></Icon>
      </Box>
    ),
  }));

  return (
    <>
      <ListSubheader>{t("oceanFishing.filters.title")}</ListSubheader>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          display: "grid",
          gridTemplateColumns: { sm: "1fr 1fr" },
          gap: 2,
          p: 2,
        }}
      >
        <RToggleButtons
          label={t("oceanFishing.filters.spot.title")}
          value={spotId}
          options={spotOptions}
          exclusive
          mandatory
          onChange={onSpotIdChange}
        />
        <RToggleButtons
          label={t("oceanFishing.filters.spotType.title")}
          value={spotTypes}
          options={spotTypeOptions}
          mandatory
          onChange={onSpotTypesChange}
        />
      </Box>
    </>
  );
};
