import { grey } from "@mui/material/colors";
import { PaletteMode, Theme } from "@mui/material";

// {
//     "mobileStepper": 1000,
//     "fab": 1050,
//     "speedDial": 1050,
//     "appBar": 1100,
//     "drawer": 1200,
//     "modal": 1300,
//     "snackbar": 1400,
//     "tooltip": 1500
// }
const getDesignTokens = (mode: PaletteMode) => ({
  ...(mode === "light"
    ? // light mode
      {
        palette: {
          mode,
          // palette values for light mode
          primary: {
            main: "#82c79d",
            contrastText: "#fff",
          },
          secondary: { main: "#2a9d8f" },
          accent: { main: "#2a9d8f" },
          info: { main: "#38669b" },
          warning: { main: "#b68738" },
          error: { main: "#c14953" },
          success: { main: "#1c7c54" },
          // divider: amber[200],
          background: {
            default: "#f2f3f5",
            paper: "#f5f5f4",
          },
          text: {
            primary: grey[900],
            secondary: grey[800],
          },
        },
        custom: {
          selected: "#1c7c54",
          row: {
            available: { main: "#9ec483", dark: "#82c79d" },
            completed: { main: "#98c1d9", dark: "#aed9e0" },
            waiting: { main: "#f1e6d3", dark: "#faf3dd" },
          },
          predatorCnt: "#e2a548",
          subheader: "#616162",
          emphasis: "#e64a19",
          label: "#616162",
        },
        components: {
          MuiAppBar: {
            styleOverrides: {
              colorPrimary: {
                backgroundColor: "#e3e5e8",
                color: "rgba(0, 0, 0, 0.87)",
              },
            },
          },
          MuiToggleButtonGroup: {
            styleOverrides: {
              grouped: {
                color: grey[800],
                "&.Mui-selected": {
                  color: "#1c7c54",
                  borderColor: "#1c7c54",
                },
              },
            },
          },
          MuiBadge: {
            styleOverrides: {
              colorPrimary: ({ theme }: { theme: Theme }) => ({
                backgroundColor: theme.palette.grey[500],
              }),
            },
          },
        },
      }
    : // dark mode
      {
        palette: {
          mode,
          // palette values for dark mode
          //     primary: {
          //       light: '#757ce8',
          //       main: '#3f50b5',
          //       dark: '#002884',
          //       contrastText: '#fff',
          //     },
          primary: { main: "#1c7c54", contrastText: "fff" },
          secondary: { main: "#2a9d8f" },
          accent: { main: "#2a9d8f" },
          info: { main: "#38669b" },
          warning: { main: "#b68738" },
          error: { main: "#c14953" },
          success: { main: "#2a9d8f" },
          // divider: deepOrange[700],
          background: {
            default: "#33333d",
            paper: "#2d2d32",
          },
          // text: {
          //   primary: '#fff',
          //   secondary: grey[500],
          // },
        },
        custom: {
          selected: "#ffffff",
          row: {
            available: { main: "#22362b", dark: "#183027" },
            completed: { main: "#173251", dark: "#0c2242" },
            waiting: { main: "#2d2d32", dark: "#272727" },
          },
          predatorCnt: "#b68738",
          subheader: "#c0c0c2",
          emphasis: "#ff4500",
          label: "#ffffff",
        },
        components: {
          MuiAppBar: {
            styleOverrides: {
              root: {
                backgroundColor: "#2d2d32",
              },
            },
          },
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: "#2d2d32",
              },
            },
          },
          MuiToggleButtonGroup: {
            styleOverrides: {
              grouped: {
                color: grey[500],
                "&.Mui-selected": {
                  color: "#fff",
                  borderColor: "#1c7c54",
                },
              },
            },
          },
          MuiBadge: {
            styleOverrides: {
              colorPrimary: ({ theme }: { theme: Theme }) => ({
                backgroundColor: theme.palette.grey[500],
              }),
            },
          },
        },
      }),
});

export default getDesignTokens;
