import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import animalsReducer from "./animalsSlice";
import oceanFishingReducer from "./oceanFishingSlice";
import settingsReducer from "./settingsSlice";
import globalReducer from "./globalSlice";
import enableWorker from "./worker";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["global", "animals"],
};

const animalsPersistConfig = {
  key: "animals",
  version: 1,
  storage,
  blacklist: ["countDown"],
};

// Nested persist
// to enable sub blacklist
// https://github.com/rt2zz/redux-persist#nested-persists
const rootReducer = combineReducers({
  animals: persistReducer(animalsPersistConfig, animalsReducer),
  oceanFishing: oceanFishingReducer,
  settings: settingsReducer,
  global: globalReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export default store;

// 从 store 本身推断出 `RootState` 和 `AppDispatch` 类型
export type RootState = ReturnType<typeof store.getState>;
// 推断出类型: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

enableWorker(store);
