import { BNpcName as BNpcNameData } from "@ffxiv-momola/data";

import { EName } from "../models/base";
import { EBNpcName } from "../models/EBNpcName";

type BNpcName = {
  id: number;
  en: string;
  ja: string;
  chs: string;
};

class BNpcNameRepository {
  readonly data: Map<number, EBNpcName>;

  constructor(bNpcNames: BNpcName[]) {
    this.data = new Map();
    for (let bNpcName of bNpcNames) {
      this.data.set(bNpcName.id, {
        id: bNpcName.id,
        name: new EName({
          chs: bNpcName.chs,
          en: bNpcName.en,
          ja: bNpcName.ja,
        }),
      } as EBNpcName);
    }
  }

  get(id: number) {
    return this.data.get(id);
  }
}

const BNpcNameRepo = new BNpcNameRepository(BNpcNameData);

export default BNpcNameRepo;
